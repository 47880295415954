import React from 'react';
import { Svg, Defs, ClipPath, Rect, G, Path } from '../Svg';
import { useEhState } from '@horos/eh-react-hooks';
import { sTheme } from '../../lib/theme';
import { Pressable } from 'react-native';

export function EditButton({ color, onPress }) {
  const { theme } = useEhState(sTheme);
  const finalColor = color || theme.colors.main;
  return (
    <Pressable onPress={onPress}>
      <Svg width="20" height="20" viewBox="0 0 16 16">
        <Defs>
          <ClipPath id="clip-path">
            <Rect width="16" height="16" fill="none" />
          </ClipPath>
        </Defs>
        <G id="Pencil_1" clipPath="url(#clip-path)">
          <Rect id="Rectangle_324" width="16" height="16" fill="none" />
          <Path
            id="Path_110"
            d="M8.154,3.077,2.462,8.923,0,16l7.077-2.308L12.769,8Zm7.231-.462-2-2a1.865,1.865,0,0,0-2.769,0L8.923,2.308l4.615,4.769,1.846-1.846A1.95,1.95,0,0,0,16,3.846,1.9,1.9,0,0,0,15.385,2.615Z"
            fill={finalColor}
          />
        </G>
      </Svg>
    </Pressable>
  );
}
