import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Platform } from 'react-native';
import styled from 'styled-components/native';
import { Button } from '../components/buttons/Button';
import { Input } from '../components/Input';
import { resi } from '../lib/resi';
import { sSession } from '../lib/session';
import { H2, H4 } from '../style/theme';
import { sDimensions } from '../style/responsive';
import { useEhState, withEhStates } from '@horos/eh-react-hooks';
import { sTheme } from '../lib/theme';

const ConnectView = styled.View`
  width: 100%;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 20px;
  max-width: 800px;
  align-self: center;

  /* background-color: red; */
`;

const TopPad = withEhStates(
  styled.View`
    /* height: 200px; */
    height: ${({ ph }) => ph(25)};
  `,
  sDimensions
);

const MiddleContainer = styled.View`
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;

  /* background-color: yellow; */
`;

async function connect(code, setError, setConnecting) {
  const codeInt = parseInt(code, 10);
  console.log('codeInt', codeInt);
  if (Number.isNaN(codeInt)) {
    console.error('Code must be number');
    setError('Code must be a number');
    return;
  }

  sSession.fire({ code: codeInt });
  setConnecting(true);
  const res = await resi.connect.connect(codeInt);
  setConnecting(false);
  if (res && res.success) {
    console.log('Connection success', res.data);
    sSession.fire({
      type: res.data.type,
      connected: true,
      reconnect: res.data.reconnect,
      iceServers: res.data.iceServers.map((i) => {
        delete i.url;
        return i;
      })
    });
  } else {
    console.error('Failed to connect', { code, res });
    if (res && res.error) {
      setError(res.error);
    } else setError('Network error');
  }
}

export function Connect() {
  const [code, setCode] = useState();
  const [error, setError] = useState();
  const [connecting, setConnecting] = useState(false);
  const {
    theme: {
      colors: { main }
    }
  } = useEhState(sTheme);

  useEffect(() => {
    if (Platform.OS === 'web') {
      // console.log('WEB LOCATION', window.location.search);
      const { location } = window;
      const possibleCode = parseInt(location.search.replace('?', ''), 10);
      if (false === isNaN(possibleCode) && possibleCode < 1000000) {
        setCode(possibleCode);
        connect(possibleCode, setError, setConnecting);
      }
    }
  }, []);

  return (
    <ConnectView nativeID="Session-Connect">
      <H2>Connect to Session</H2>
      <TopPad />
      <MiddleContainer>
        {connecting ? (
          <ActivityIndicator color={main} size="large" />
        ) : (
          [
            <H4 key={0}>Input session code</H4>,
            <Input
              key={1}
              style={{ marginTop: 10 }}
              onChangeText={(x) => {
                setCode(x);
                setError();
              }}
              placeholder="input code here"
              error={error}
              keyboardType="number-pad"
            />
          ]
        )}
      </MiddleContainer>
      {/* <BottomPad /> */}
      <Button
        onPress={() => connect(code, setError, setConnecting)}
        text={connecting ? 'Connecting...' : 'Connect'}
        mode="default"
      />

      {/* <Button
        text="Play"
        onPress={async () => {
          console.log('playing!');
          const rec = new Sound('temp.aac', Sound.DOCUMENT, (error) => {
            if (error) {
              console.log('failed to load the sound', error);
              return;
            }
            // loaded successfully
            console.log(
              'duration in seconds: ' +
                rec.getDuration() +
                'number of channels: ' +
                rec.getNumberOfChannels()
            );

            // Play the sound with an onEnd callback
            rec.play((success) => {
              if (success) {
                console.log('successfully finished playing');
                rec.release();
              } else {
                console.log('playback failed due to audio decoding errors');
              }
            });
          });
        }}
      /> */}
    </ConnectView>
  );
}
