export class ErsoRecorder {
  /**
   *
   * @param {string} path
   */
  async start() {}

  /**
   *
   * @param {FormData} data
   */
  async stopAndPopulateFormData(data) {}
}
