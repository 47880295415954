import { withEhStates } from '@horos/eh-react-hooks';
import React from 'react';
import styled from 'styled-components/native';
import { sVideoSession } from '../lib/session';
import { sTheme } from '../lib/theme';
import { v } from '../lib/ui-version';

export const HEADER_HEIGHT = 40;

const OuterContainer = withEhStates(
  styled.View`
    width: 100%;

    background-color: ${({ theme }) => theme.colors.main};
    /* background-color: ${({ theme, isRecording }) =>
      isRecording ? theme.colors.secondary : theme.colors.main}; */
  `,
  sTheme,
  sVideoSession
);

const SafeContainer = styled.SafeAreaView`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const Title = styled.Text`
  color: white;
  font-size: 20px;
`;

const SideContent = styled.View`
  width: 60px;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

  /* background-color: blue; */
`;

const VersionText = styled.Text`
  font-size: 10px;
  margin-right: 10px;
  color: white;
`;

export function Header() {
  return (
    <OuterContainer>
      <SafeContainer>
        <SideContent />
        <Title>Erso VT Session</Title>
        <SideContent>
          <VersionText>{v}</VersionText>
        </SideContent>
      </SafeContainer>
    </OuterContainer>
  );
}
