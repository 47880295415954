import { beep } from '../beep';
import { resi } from '../resi';
import { dequeuer, MSG, sSession, sVideoSession } from '../session';
import { Ramirez } from './ramirez';
export class PatientRamirez extends Ramirez {
  constructor() {
    super('Patient', resi.patient.enqueue);
  }

  async handleOffer(payload) {
    await this.initStream();
    await this.pc.setRemoteDescription(payload);
    sSession.fire({ operator_offer: payload });
    const answer = await this.pc.createAnswer();
    await this.pc.setLocalDescription(answer);
    resi.patient.enqueue(this.code, MSG.answer(answer));
  }

  /**
   *
   * @param {import('../session').DequeuerHandlerArgument} msg
   * @returns
   */
  async messageHandler(msg) {
    const { type, payload } = msg;
    console.log('PATIENT DEQUEUE', { type });
    switch (type) {
      case 'offer':
        this.resendRecordingSettings();
        await this.handleOffer(payload);
        break;
      case 'ice_candidate':
        if (!payload || payload === '') {
          console.warn('PATIENT Ignoring bad ICE candidate');
        }

        return this.pc.addIceCandidate(payload).catch((e) =>
          console.warn('PATIENT failed to add ice candidate', {
            e,
            payload
          })
        );

      case 'toggle_recording':
        console.log('TOGGLING RECORDING', { payload });
        await sVideoSession.fire({ isRecording: payload });
        resi.patient.enqueue(this.code, MSG.toggle_recording(payload));
        break;
      case 'toggle_recording_fake':
        if (true === payload) beep();
        await sVideoSession.fire({ isRecordingFake: payload });
        resi.patient.enqueue(this.code, MSG.toggle_recording_fake(payload));
        break;
      case 'record_media_response':
        this.enqueue(this.code, msg);
        return this.commonHandler(msg);
      default:
        return this.commonHandler(msg);
    }
  }

  async start(code, isRestart = false) {
    super.start(code);
    console.log('Patient Ramirez Start', { code });
    this.init();
    const boundMessageHandler = this.messageHandler.bind(this);

    if (false === isRestart) dequeuer(code, 'patient', boundMessageHandler);

    this.pc.onicecandidate = (e) => {
      resi.patient.enqueue(code, MSG.ice_candidate(e.candidate));
    };

    this.registerForStreamEvent();

    if (sSession.state.reconnect) {
      console.log('PATIENT issueing RECONNECT request on start');
      sSession.fire({ reconnect: false });
      resi.patient.enqueue(code, MSG.restart());
    }
  }
}
