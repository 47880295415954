import { withEhStates } from '@horos/eh-react-hooks';
import styled from 'styled-components/native';
import { sDimensions } from '../style/responsive';

export const ModalContainer = withEhStates(
  styled.ScrollView`
    width: ${({ pw }) => pw(100)};
    height: ${({ ph }) => ph(100)};
    /* position: absolute;
    left: 0;
    top: 0; */

    background-color: rgba(0, 0, 0, 0.6);
  `,
  sDimensions
);
