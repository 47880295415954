import { useEhState } from '@horos/eh-react-hooks';
import React, { useMemo } from 'react';
import styled from 'styled-components/native';
import { stopRecording } from '../lib/record';
import { resi, resiStandardResponseHandler } from '../lib/resi';
import { sSession, sVideoSession } from '../lib/session';
import { sTheme } from '../lib/theme';
import { AttributeButton } from './buttons/Attribute';
import { Margin } from './Layout';

const Container = styled.View`
  flex-direction: row;
  /* background-color: fuchsia; */
`;

const PairContainer = styled.View`
  align-items: center;
  /* background-color: red; */
`;

const Label = styled.Text`
  margin-vertical: 5px;
`;

async function updateEloaded(
  eloaded,
  loadLevel,
  currentRecording,
  isRecording,
  code
) {
  if (!currentRecording.id) return;

  if (isRecording) stopRecording(code);

  const res = await resi.operator.attribute_recording(
    currentRecording.id,
    eloaded,
    loadLevel
  );
  const newRecording = resiStandardResponseHandler(res);
  if (newRecording) sVideoSession.fire({ currentRecording: newRecording });
}

/**
 *
 * @param {boolean} hasId
 * @param {Theme} theme
 * @returns
 */
function getColorScheme(hasId, theme) {
  if (hasId)
    return {
      // loaded: LightenDarkenColor(theme.colors.secondary, 30),
      // neutralDirty: LightenDarkenColor(theme.colors.main, 0),
      // loadedVery: '#a30202',
      // neutralClear: '#7fa5ff'
      loaded: '#ff8bbb',
      neutralDirty: '#ffff33',
      loadedVery: '#ff0000',
      neutralClear: '#33ff22'
    };
  else
    return {
      loaded: 'grey',
      neutralDirty: 'grey',
      loadedVery: 'grey',
      neutralClear: 'grey'
    };
}

export function Attribution() {
  const { theme } = useEhState(sTheme);

  const { code } = useEhState(sSession);
  const { currentRecording, isRecording } = useEhState(sVideoSession);
  const hasId = Boolean(currentRecording.id);
  const { loaded, loadedVery, neutralClear, neutralDirty } = useMemo(
    () => getColorScheme(hasId, theme),
    [hasId, theme]
  );
  return (
    <Container>
      <PairContainer>
        <Label style={{ color: loaded }}>loaded</Label>
        <AttributeButton
          color={loaded}
          onPress={() =>
            updateEloaded(true, 3, currentRecording, isRecording, code)
          }
        />
        <AttributeButton
          style={{ marginTop: 10 }}
          color={neutralDirty}
          rotate
          onPress={() =>
            updateEloaded(false, 2, currentRecording, isRecording, code)
          }
        />
        <Label style={{ color: neutralDirty }}>netural dirty</Label>
      </PairContainer>
      <Margin width={10} />
      <PairContainer>
        <Label style={{ color: loadedVery }}>very loaded</Label>
        <AttributeButton
          color={loadedVery}
          onPress={() =>
            updateEloaded(true, 4, currentRecording, isRecording, code)
          }
        />
        <AttributeButton
          style={{ marginTop: 10 }}
          color={neutralClear}
          rotate
          onPress={() =>
            updateEloaded(false, 1, currentRecording, isRecording, code)
          }
        />
        <Label style={{ color: neutralClear }}>netural clear</Label>
      </PairContainer>
    </Container>
  );
}
