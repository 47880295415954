import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { Input } from '../components/Input';
import { ErsoButton } from '../components/buttons/Button';
import { EditButton } from '../components/buttons/Edit';
import { H2, H3 } from '../style/theme';
import { resi } from '../lib/resi';
import { sPatientSearch, submitSearchTerm } from '../lib/session-creation';
import { SearchResults } from '../components/SearchResults';
import { useEhState, withEhStates } from '@horos/eh-react-hooks';
import { sTheme } from '../lib/theme';
import { sSession } from '../lib/session';
import { makeRsp, plugRsp, sDimensions } from '../style/responsive';

const Container = styled.View`
  width: 100%;
  height: 100%;
  align-items: center;
  padding-top: 35px;
`;

const SelectionConatiner = styled.View`
  width: 100%;
  /* height: 40%; */
  justify-content: space-between;
  align-items: center;
`;

const SelectedContainer = styled.View`
  width: 100%;
  height: 120px;
  justify-content: space-between;
  align-items: center;

  /* background-color: red; */
`;

const SelectedButtonsContainer = withEhStates(
  styled.View`
    height: 75px;
    /* margin-top: 100px; */
    margin-top: ${makeRsp('height', plugRsp('global', 50, 100, 30, 100))};
    justify-content: space-between;

    /* background-color: blue; */
    /* width: 100%; */
    z-index: 9;
  `,
  sDimensions
);

const SelectedPatientRow = styled.View`
  flex-direction: row;
`;

const SelectedPatientRowSide = styled.View`
  flex-direction: row;
  flex-grow: 1;
  max-width: 30%;
  /* flex-grow: 1; */
  justify-content: center;
  align-items: center;

  /* background-color: yellow; */
`;
const SelectedPatientRowCenter = styled.View`
  flex-direction: row;
  justify-content: space-between;
  justify-content: center;
  min-width: 30%;
  flex-grow: 1;
  /* width: 40%; */
  /* background-color: blue; */
`;

function createSessionOperation(resiFunc, selectedPatient) {
  resiFunc(selectedPatient).then((res) => {
    console.log('res', res);
    if (res && res.success && res.data) {
      sSession.fire({
        code: res.data,
        patient_id: selectedPatient.id
      });
    }
  });
}

export function SessionCreator() {
  const [selectedPatient, setSelectedPatient] = useState();
  const [value, setValue] = useState('');
  console.log('value', {
    mode: value && value.length > 3 ? 'default' : 'disabled'
  });

  const { theme } = useEhState(sTheme);
  const { error, searchResults } = useEhState(sPatientSearch);

  useEffect(() => {
    submitSearchTerm('-1');
  }, []);

  if (!selectedPatient)
    return (
      <Container>
        <SelectionConatiner>
          <H2 style={{ marginBottom: 15 }}>Select or Create Patient</H2>
          <Input
            value={value}
            // onPressIn={() => setIsSearching(true)}
            onChangeText={(t) => {
              setValue(t);
              submitSearchTerm(t);
            }}
            error={error}
          />
          <SearchResults
            results={searchResults}
            style={{ marginVertical: 15 }}
            onSelected={(p) => {
              setSelectedPatient(p);
              // resetPatientSearch();
              // setValue();
            }}
          />
          <ErsoButton
            mode={value && value.length > 3 ? 'default' : 'disabled'}
            text="Create New Patient"
            onPress={() => {
              if (!value) sPatientSearch.fire({ error: 'Must input name' });
              else if (value.length <= 3) {
                sPatientSearch.fire({
                  error: 'Name must be longer than 3 letters'
                });
              } else if (false === value.includes(' ')) {
                sPatientSearch.fire({ error: 'Must provide full name' });
              } else
                resi.operator.create_patient(value).then((res) => {
                  if (!res)
                    sPatientSearch.fire({
                      error: 'Network error. Please try again'
                    });
                  else if (res.error) {
                    sPatientSearch.fire({ error: res.error });
                  } else {
                    setSelectedPatient({ name: value, id: res.data.id });
                  }
                });
            }}
          />
        </SelectionConatiner>
      </Container>
    );
  else
    return (
      <Container>
        <SelectedContainer>
          <H2>Create Session</H2>
          <H3>Selected Patient</H3>
          <SelectedPatientRow>
            <SelectedPatientRowSide />
            <SelectedPatientRowCenter>
              <H2 style={{ color: theme.colors.main }}>
                {selectedPatient.name}
                {', '}
              </H2>
              <H2 style={{ color: theme.colors.main }}>{selectedPatient.id}</H2>
            </SelectedPatientRowCenter>
            <SelectedPatientRowSide>
              <EditButton onPress={() => setSelectedPatient(null)} />
            </SelectedPatientRowSide>
          </SelectedPatientRow>
        </SelectedContainer>
        <SelectedButtonsContainer>
          <ErsoButton
            mode={selectedPatient.last_session_id ? 'default' : 'disabled'}
            text="Continue Last Session"
            onPress={() => {
              if (!selectedPatient.last_session_id) return;
              createSessionOperation(
                resi.operator.continue_session,
                selectedPatient
              );
            }}
          />
          <ErsoButton
            mode="warning"
            text="Start New Session"
            onPress={() => {
              createSessionOperation(
                resi.operator.new_session,
                selectedPatient
              );
            }}
          />
        </SelectedButtonsContainer>
      </Container>
    );
}
