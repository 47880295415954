export function beep() {
  console.log('beeping web');
  let resolve = null;
  const audio = new Audio('/beep.mp3');
  const promise = new Promise((resolve_) => {
    resolve = resolve_;
  });
  audio.onended = () => resolve();
  audio.play();
  return promise;
}
