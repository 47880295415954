import React, { useState } from 'react';
import styled from 'styled-components/native';
import { Pressable } from 'react-native';
import { Svg, Defs, LinearGradient, G, Rect, Stop } from '../Svg';
import { styledShadow } from '../../lib/styled-shadow-cx';
import { sStageModal } from '../StageModal';

const Container = styled(Pressable)`
  width: ${({ small }) => (small ? '100px' : '160px')};
  height: 25px;
  border-radius: 10px;

  justify-content: center;
  align-items: center;

  border: 1px solid #707070;
  background-color: ${({ pressed }) => (pressed ? '#d1d1d1' : '#fff')} 
  overflow: hidden;
  ${({ pressed }) => (pressed ? '' : styledShadow(5))}
`;

const ButtonText = styled.Text`
  z-index: 2;
`;

function Background({ pressed }) {
  return (
    <Svg
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }}
      viewBox="0 0 140 25"
      width="160">
      <Defs>
        <LinearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox">
          <Stop offset="0" stopColor={pressed ? '#d1d1d1' : '#fff'} />
          <Stop offset="1" stopColor={pressed ? '#fff' : '#d1d1d1'} />
        </LinearGradient>
      </Defs>
      <G
        width="100%"
        height="100%"
        id="Rectangle_12"
        // dataName="Rectangle 12"
        fill="url(#linear-gradient)">
        <Rect width="160" height="100%" rx="10" stroke="none" />
        <Rect x="0.5" y="0.5" width="160" height="24" rx="9.5" fill="none" />
      </G>
    </Svg>
  );
}

export function StageButton({ color, options, value, onUpdate, small }) {
  const [pressed, setPressed] = useState(false);
  return (
    <Container
      small={small}
      pressed={pressed}
      onPress={() => {
        if (options && options.length > 0)
          sStageModal.fire({ on: true, options, color, onUpdate });
      }}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}>
      {/* <Background pressed={pressed} /> */}
      <ButtonText style={{ color }}>{value}</ButtonText>
    </Container>
  );
}
