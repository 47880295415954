/** @typedef {"Initial Neutral"|"Initial Loaded Seek"|"Initial Loaded Organ"|"Emotion Detection"|"Time Detection"|"Circumstances Detection"|"Final Loaded"} TreatmentStage */

export const TreatmentStages = {
  InitialNeutral: 'Initial Neutral',
  InitialLoadedSeek: 'Initial Loaded Seek',
  InitialLoadedOrgan: 'Initial Loaded Organ',
  EmotionDetection: 'Emotion Detection',
  TimeDetection: 'Time Detection',
  CircumstancesDetection: 'Circumstances Detection',
  FinalLoaded: 'Final Loaded'
};

export class Recording {
  /**
   *
   * @param {number} id
   * @param {number} sessionId
   * @param {boolean} eloaded
   * @param {number} orgidx
   * @param {number} seekidx
   * @param {number} session_part
   * @param {TreatmentStage} treatment_stage
   * @param {string} emotion
   * @param {string} emotion_time
   * @param {string} circumstance
   * @param {string} audio_url
   * @param {string} video_url
   */
  constructor(
    sessionId,
    session_part,
    treatment_stage,
    orgidx,
    emotion,
    emotion_time,
    circumstance,
    audio_url,
    video_url,
    eloaded,
    seekidx,
    id
  ) {
    this.id = id;
    this.session = sessionId;
    this.eloaded = eloaded;
    this.treatment_stage = treatment_stage;
    this.orgidx = orgidx;
    this.emotion = emotion;
    this.emotion_time = emotion_time;
    this.circumstance = circumstance;
    this.audio_url = audio_url;
    this.video_url = video_url;
    this.seekidx = seekidx;
    this.session_part = session_part;
  }

  /**
   *
   * @param {Recording} rec
   * @returns
   */
  static newWithSession(rec, treatment_stage = rec.treatment_stage) {
    const { session, session_part } = rec;
    return new Recording().assign({ session, session_part, treatment_stage });
  }

  /**
   *
   * @param {Recording} rec
   */
  static newWithRec(rec, treatment_stage = rec.treatment_stage) {
    const {
      session,
      session_part,
      orgidx,
      emotion,
      emotion_time,
      circumstance
    } = rec;
    return new Recording().assign({
      session,
      session_part,
      treatment_stage,
      orgidx,
      emotion,
      emotion_time,
      circumstance
    });
  }

  /**
   *
   * @param {Recording} recordingFields
   */
  static assignCreate(recordingFields) {
    const newrec = new Recording();
    Object.assign(newrec, recordingFields);
    return newrec;
  }

  /**
   *
   * @param {Recording} recordingFields
   */
  assign(recordingFields) {
    Object.assign(this, recordingFields);
    return this;
  }
}
