import React, { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components/native';
import { useEhState, withEhStates } from '@horos/eh-react-hooks';
import { InternalEnqueue, MSG, sSession, sVideoSession } from '../lib/session';
import { OperatorRamirez } from '../lib/ramirez-agents/operator';
import { VideoCall, VIDEO_PW_DIM } from '../components/VideoCall';
import { ShareButton } from '../components/buttons/Share';
import { RecordButton } from '../components/buttons/Record';
import { StageButton } from '../components/buttons/StageButton';
import { Attribution } from '../components/Attribution';
import { ControllerContainer } from '../components/ControllerContainer';
import { sDimensions } from '../style/responsive';
import { sTheme } from '../lib/theme';
import { resi, resiStandardResponseHandler } from '../lib/resi';
import { Recording } from '../resi-client/models/recording';
import { RecordFakeButton } from '../components/buttons/RecordFake';
import ToggleSwitch from '../components/ToggleSwitch';
import { LightenDarkenColor } from 'lighten-darken-color';
import { isToggleOn, useIsWideLayout } from '../lib/utils';
import { Margin } from '../components/Layout';

const OperatorContainer = withEhStates(
  styled.ScrollView`
    /* width: ${VIDEO_PW_DIM}; */
    width: 100%;
    align-self: center;
    /* align-items: center; */
    padding-top: 20px;
    /* justify-content: center; */
    /* background-color: red; */
  `,
  sDimensions
);

const SessionHeaderContainer = withEhStates(
  styled.View`
    width: ${VIDEO_PW_DIM};

    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 20px;
    /* background-color: red; */
  `,
  sDimensions
);

const SessionHeaderText = styled.Text`
  font-size: 20px;
`;

const ControlsSection = styled.View`
  /* width: ${({ fullScreen }) => (fullScreen ? '50%' : '100%')}; */
  width: 100%;
  /* margin-left: ${({ fullScreen }) => (fullScreen ? 10 : 0)}; */
  /* width: 100%; */
  /* height: 100%; */
  align-self: flex-start;

  background-color: ${({ fullScreen }) =>
    fullScreen ? 'rgba(255, 255, 255, 0.4)' : 'transparent'};
  /* padding: 15px; */

  /* background-color: blue; */
`;

const HorControlsSection = withEhStates(
  styled.View`
    flex-direction: ${({ fullScreen }) => (fullScreen ? 'column' : 'row')};
    /* width: ${VIDEO_PW_DIM}; */
    width: 100%;
    /* background-color: red; */
  `,
  sDimensions
);

const ResponsiveContainer = withEhStates(
  styled.View`
    flex-direction: ${({ isLandscape }) => (isLandscape ? 'row' : 'column')};
    align-items: center;
    justify-content: space-evenly;
  `,
  sDimensions
);

const RecordingToggleContainer = styled.View`
  height: 200px;
`;

const Spacer = styled.View`
  flex-grow: 1;
`;

function SessionHeader({ code }) {
  return (
    <SessionHeaderContainer>
      <SessionHeaderText>Session Code: {code}</SessionHeaderText>
      <ShareButton code={code} />
    </SessionHeaderContainer>
  );
}

const selection_by_idx = [
  null,
  null,
  'orgidx',
  'emotion',
  'emotion_time',
  'circumstance',
  null
];
/**
 *
 * @param {Recording} recording
 */
function selectSecondaryStage(recording, stageMetadata) {
  if (!stageMetadata || !stageMetadata.treatment_stages) return 'Loading';

  const indexOfTreatmentStage = stageMetadata.treatment_stages.indexOf(
    recording.treatment_stage
  );
  const selection = selection_by_idx[indexOfTreatmentStage];

  let value = selection ? recording[selection] : '-';
  if (selection === 'orgidx' && undefined !== value)
    value = stageMetadata.organs[value];

  // console.log('selectSecondaryStage', {
  //   recording,
  //   stageMetadata,
  //   indexOfTreatmentStage,
  //   selection
  // });
  return [value, selection];
}

function mainStageUpdate(treatment_stage, currentRecording, stageMetadata) {
  console.log('treatment stage update', treatment_stage);
  const { session, session_part } = currentRecording;
  let newRecording = new Recording().assign({
    treatment_stage,
    session,
    session_part
  });

  const [, selection] = selectSecondaryStage(newRecording, stageMetadata);
  console.log('selection', selection);
  if (selection === 'Initial Loaded Seek') {
    newRecording.seekidx = 0;
  } else if (selection === 'orgidx') {
    newRecording.orgidx = 0;
  } else if (selection) {
    Object.assign(newRecording, currentRecording);
    newRecording[selection] = stageMetadata[selection + 's'][0];
  }
  console.log('treatment stage update newRecording', newRecording);
  sVideoSession.fire({ currentRecording: newRecording });
}

function secondaryStageUpdate(currentRecording, secondaryStage, idx, value) {
  console.log('Secondary Update', value);
  const newRecording = Object.assign(new Recording(), currentRecording);
  if (secondaryStage[1] === 'orgidx') newRecording[secondaryStage[1]] = idx;
  else if (secondaryStage[1]) {
    newRecording[secondaryStage[1]] = value;
  } else return;

  sVideoSession.fire({ currentRecording: newRecording });
}

export function Operator() {
  const { code, useForResearch } = useEhState(sSession);

  const { fullScreen, currentRecording, recordAudio, recordVideo } =
    useEhState(sVideoSession);

  const { theme } = useEhState(sTheme);

  const wideLayout = useIsWideLayout();

  const [stageMetadata, setStageMetadata] = useState();

  useEffect(() => {
    resi.operator.get_metadata().then((res) => {
      const data = resiStandardResponseHandler(res);
      console.log('data', data);
      if (data) setStageMetadata(data);
    });

    resi.operator.get_next_recording(code).then((res) => {
      const newRecording = resiStandardResponseHandler(res);
      if (newRecording) sVideoSession.fire({ currentRecording: newRecording });
    });
  }, [setStageMetadata, code]);

  useEffect(() => {
    console.log('Operator Starting');
    const operator_ = new OperatorRamirez();
    operator_.start(code);
  }, [code]);

  const secondaryStage = useMemo(
    () => selectSecondaryStage(currentRecording, stageMetadata),
    [currentRecording, stageMetadata]
  );
  // console.log('secondary_stage', secondary_stage);

  const secondaryStageOptions = useMemo(() => {
    if (secondaryStage[1] === 'emotion') {
      return Object.values(stageMetadata.ote)[currentRecording.orgidx].emotions;
    } else if (secondaryStage[1] === 'orgidx') {
      return stageMetadata.organs;
    } else {
      return (
        stageMetadata &&
        secondaryStage[1] &&
        stageMetadata[secondaryStage[1] + 's']
      );
    }
  }, [secondaryStage, stageMetadata, currentRecording]);

  console.log('secondaryStageOptions', secondaryStageOptions);
  const controls = (
    <ControlsSection fullScreen={fullScreen}>
      <ControllerContainer
        horizontal={!fullScreen}
        name="Stage"
        style={{
          height: fullScreen ? 100 : 'auto',
          justifyContent: 'space-evenly'
          // backgroundColor: 'yellow'
          // width: '100%'
        }}>
        <StageButton
          // small={fullScreen}
          value={currentRecording.treatment_stage}
          color={LightenDarkenColor(theme.colors.main, -30)}
          options={stageMetadata?.treatment_stages}
          onUpdate={(treatment_stage) =>
            mainStageUpdate(treatment_stage, currentRecording, stageMetadata)
          }
        />
        <StageButton
          // small={fullScreen}
          value={secondaryStage[0]}
          color={theme.colors.secondary}
          options={secondaryStageOptions}
          onUpdate={(value, idx) =>
            secondaryStageUpdate(currentRecording, secondaryStage, idx, value)
          }
        />
      </ControllerContainer>
      <HorControlsSection fullScreen={fullScreen}>
        <ControllerContainer
          name="Record"
          horizontal
          style={false === fullScreen && { width: '50%' }}>
          <RecordButton />
          <RecordFakeButton />
        </ControllerContainer>
        <ControllerContainer name="Attribute">
          <Attribution />
        </ControllerContainer>
      </HorControlsSection>
    </ControlsSection>
  );

  const videoCall = (
    <VideoCall showLocal user="Operator">
      {fullScreen && controls}
    </VideoCall>
  );

  if (fullScreen) return videoCall;
  return (
    <OperatorContainer contentContainerStyle={{ alignItems: 'center' }}>
      {/* <H2 style={{ marginBottom: 40 }}>Welcome Operator</H2> */}
      <SessionHeader code={code} />
      <ResponsiveContainer wideLayout={wideLayout}>
        {videoCall}
        {/* {false === fullScreen && controls} */}
        {false === fullScreen && (
          <RecordingToggleContainer>
            {false === wideLayout && <Margin height={20} />}
            <ToggleSwitch
              isOn={isToggleOn(recordAudio)}
              onColor={
                recordAudio === 'pending'
                  ? theme.colors.fakeRecord
                  : theme.colors.main
              }
              offColor={theme.colors.secondary}
              label="Record Audio"
              labelStyle={{ color: 'black', fontWeight: '900' }}
              size="medium"
              onToggle={(isOn) => {
                console.log('RecordAudio isOn', isOn);
                InternalEnqueue(MSG.record_media_request('Audio', isOn));
                // InternalEnqueue(MSG.toggle_record_audio(isOn));
              }}
            />
            <Margin />
            <ToggleSwitch
              isOn={isToggleOn(recordVideo)}
              onColor={
                recordVideo === 'pending'
                  ? theme.colors.fakeRecord
                  : theme.colors.main
              }
              offColor={theme.colors.secondary}
              label="Record Video"
              labelStyle={{ color: 'black', fontWeight: '900' }}
              size="medium"
              onToggle={(isOn) =>
                InternalEnqueue(MSG.record_media_request('Video', isOn))
              }
            />
            <Margin height={20} />
            <ToggleSwitch
              isOn={useForResearch}
              onColor={theme.colors.fakeRecord}
              offColor={theme.colors.secondary}
              label="Use For Research"
              labelStyle={{ color: theme.colors.fakeRecord, fontWeight: '900' }}
              size="large"
              onToggle={(isOn) => {
                sSession.fire({ useForResearch: isOn });
                resi.operator.set_use_for_research(code, isOn);
              }}
            />
          </RecordingToggleContainer>
        )}
      </ResponsiveContainer>
    </OperatorContainer>
  );
}
