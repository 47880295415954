import { useEhState } from '@horos/eh-react-hooks';
import React from 'react';
import styled from 'styled-components/native';

import { sTheme } from '../lib/theme';

const OuterContainer = styled.View`
  height: 40px;
  width: 80%;
  border-radius: 15px;
  border-color: ${({ error }) => (error ? 'red' : '#707070')};
  border-width: 1px;
  align-items: center;
`;

const StyledTextInput = styled.TextInput`
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  align-self: center;
  /* background-color: red; */
`;

const ErrorText = styled.Text`
  color: red;
`;

/**
 *
 * @param {import('react-native').TextInputProps & {error: string}} param0
 * @returns
 */
export function Input({ style, error, ...props }) {
  const { theme } = useEhState(sTheme);

  return (
    <OuterContainer style={style} error={error}>
      <StyledTextInput
        style={{ color: theme.colors.main, textAlign: 'center' }}
        {...props}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </OuterContainer>
  );
}
