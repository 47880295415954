import { useEhState } from '@horos/eh-react-hooks';
import { sDimensions } from '../style/responsive';

/**
 *
 * @param {boolean} boolVal
 */
export const NOT = (boolVal) => !boolVal;

export const mediaRecordingTogglersOnModes = ['confirmed', 'pending'];

export const getTogglerOnColor = (state, theme) =>
  state === 'pending' ? theme.colors.fakeRecord : theme.colors.main;

export const isToggleOn = (state) =>
  mediaRecordingTogglersOnModes.includes(state);

export function useIsWideLayout() {
  const {
    dimensions: { width },
    isLandscape
  } = useEhState(sDimensions);

  const isWide = width > 800 && isLandscape;
  return isWide;
}
