/**
 * @template T
 */
export class ManualResetEvent {
  constructor() {
    this.reset();
  }

  reset() {
    /** @type {Promise<T>} */
    this.promise = new Promise((resolve) => {
      this.resolve = resolve;
    });
  }

  /**
   *
   * @returns {Promise<T>}
   */
  wait() {
    return this.promise;
  }

  /**
   *
   * @param {T} data
   */
  release(data, reset = false) {
    this.resolve(data);
    if (reset) this.reset();
  }
}
