import { withEhStates } from '@horos/eh-react-hooks';
import React from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { styledShadow } from '../../lib/styled-shadow-cx';
import { sTheme } from '../../lib/theme';

const ButtonView = withEhStates(
  styled.View`
    width: 180px;
    height: 30px;
    background-color: ${({ theme, mode }) => {
      switch (mode) {
        case 'disabled':
          return 'grey';
        case 'warning':
          return theme.colors.secondary;
        case 'default':
        default:
          return theme.colors.main;
      }
    }};
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    ${({ mode }) => (mode === 'disabled' ? '' : styledShadow(4))};
  `,
  sTheme
);

const ButtonText = styled.Text`
  color: white;
`;

/**
 *
 * @param {{text:string, mode: 'default' | 'disabled' | 'warning', onPress:(event:import('react-native').GestureResponderEvent) => void}} param0
 * @returns
 */
export function Button({ text, mode, onPress }) {
  return (
    <Pressable onPress={() => mode !== 'disabled' && onPress()}>
      <ButtonView mode={mode}>
        <ButtonText>{text}</ButtonText>
      </ButtonView>
    </Pressable>
  );
}

export const ErsoButton = Button;
