import React from 'react';
import styled from 'styled-components/native';
import { Svg, Polygon } from '../Svg';
import { Pressable } from 'react-native';
import { LightenDarkenColor } from 'lighten-darken-color';
import { makeRsp, plugRsp, sDimensions } from '../../style/responsive';
import { withEhStates } from '@horos/eh-react-hooks';

const ButtonHeight = makeRsp('height', plugRsp('global', 25, 50, 20, 35));
const ButtonWidth = makeRsp('width', plugRsp('global', 40, 80, 35, 65));

const ButtonContainer = withEhStates(
  styled(Pressable)`
    height: ${ButtonHeight};
    width: ${ButtonWidth};
  `,
  sDimensions
);

/**
 *
 * @param {{color: string, rotate:boolean}} param0
 */
export function AttributeButton({ style, color, rotate, onPress }) {
  // const { theme: {colors: {main, secondary}} } = useEhState(sTheme);
  return (
    <ButtonContainer style={style} onPress={onPress}>
      {({ pressed }) => (
        <Svg
          width="65"
          height="35"
          // viewBox="0 0 65 35"
          // viewBox="0 5 5 40"
          viewBox="0 0 65 35"
          // rotation={rotate ? 180 : 0}
          transform={rotate ? 'rotate(180)' : ''}>
          <Polygon
            points="0,35 65,35 32.5,0"
            fill={pressed === true ? LightenDarkenColor(color, 50) : color}
          />
        </Svg>
      )}
    </ButtonContainer>
  );
}
