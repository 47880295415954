import React, { useEffect } from 'react';
import { View } from 'react-native';
import { H2 } from '../style/theme';
import { VideoCall } from '../components/VideoCall';
import { useEhState } from '@horos/eh-react-hooks';
import { sSession, sVideoSession } from '../lib/session';
import { PatientRamirez } from '../lib/ramirez-agents/patient';
import { resi } from '../lib/resi';

import { recorder } from '../lib/recorder';
import { beep } from '../lib/beep';

async function soundRecorderEffect(isRecording, code) {
  const { patient_id } = sSession.state;
  const { recordAudio, recordVideo } = sVideoSession.state;
  if (isRecording) {
    console.log('RECORDING START');

    await beep();

    await recorder.start(
      recordAudio === 'confirmed',
      recordVideo === 'confirmed'
    );
  } else if (false === isRecording) {
    try {
      console.log('RECORDING STOP');

      const data = new FormData();
      await recorder.stopAndPopulateFormData(data);
      data.append('sessionId', code);
      console.log('data for send', data);
      await resi.patient.submit_recording(data);
    } catch (error) {
      resi.patient.log(`Failed to upload recording. E=${error}`, patient_id);
      console.error('Error while stopping record', error);
    }
  } else return;
}

// /**
//  *
//  * @param {string} media
//  * @param {boolean} value
//  * @param {import('react-native-toast-notifications').ToastType} toast
//  */
// function useRecordToggleToast(media, value, toast) {
//   const mountedRef = useRef(false);

//   useEffect(() => {
//     if (mountedRef.current) {
//       toast.show(`${media} recording ${value ? 'on' : 'off'}`, {
//         type: 'normal',
//         placement: 'top',
//         offset: 30,
//         animationType: 'zoom-in'
//       });
//     } else {
//       mountedRef.current = true;
//     }
//   }, [value, media, toast]);
// }

export function Patient() {
  const { code } = useEhState(sSession);
  const { fullScreen, isRecording } = useEhState(sVideoSession);

  // const toast = useToast();

  // useRecordToggleToast('Audio', recordAudio, toast);
  // useRecordToggleToast('Video', recordVideo, toast);

  useEffect(() => {
    if (code) {
      resi.patient.log(
        `Patient starting session. Agent: ${navigator.userAgent}`,
        0
      );
      new PatientRamirez().start(code);
    }
  }, [code]);

  useEffect(() => {
    soundRecorderEffect(isRecording, code);
  }, [isRecording, code]);

  const videoCall = <VideoCall user="Patient" />;

  if (fullScreen) return videoCall;
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 20,
        height: '100%'
        // backgroundColor: 'green'
      }}>
      <H2 style={{ marginBottom: 40 }}>Welcome to an Erso VT Session</H2>
      {videoCall}
      {/* <DevPlayer isRecording={isRecording} /> */}
    </View>
  );
}
