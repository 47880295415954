import React from 'react';
import styled from 'styled-components/native';
import { Pressable } from 'react-native';
import { Svg, Defs, RadialGradient, Stop, G, Polygon } from '../Svg';
import { useEhState, withEhStates } from '@horos/eh-react-hooks';
import { MSG, sSession, sVideoSession } from '../../lib/session';
import { sTheme } from '../../lib/theme';
import { resi } from '../../lib/resi';
import { makeRsp, plugRsp, sDimensions } from '../../style/responsive';

const ButtonDim = makeRsp('height', plugRsp('global', 60, 90, 40, 65));
const ButtonContainer = withEhStates(
  styled.View`
    /* background-color: blue; */
    height: ${ButtonDim};
    width: ${ButtonDim};
  `,
  sDimensions
);

export function RecordFakeButton() {
  const { isRecordingFake } = useEhState(sVideoSession);
  const { code } = useEhState(sSession);
  const {
    theme: {
      colors: { fakeRecord }
    }
  } = useEhState(sTheme);

  return (
    <Pressable
      onPress={() => {
        const op = !isRecordingFake;
        resi.operator.enqueue(code, MSG.toggle_recording_fake(op));
        // const op = isRecording === true ? stopRecording : startRecording;
        // op(code, currentRecording);
      }}>
      {({ pressed }) => (
        <ButtonContainer pressed={pressed}>
          <Svg
            //   width="88"
            //   height="88"
            viewBox="0 0 110 110"
            preserveAspectRatio="xMinYMin slice">
            <Defs>
              <RadialGradient
                id="radial-gradient"
                cx="0.5"
                cy="0.5"
                r="1.212"
                gradientTransform="translate(-0.171 0.278) rotate(-26.696)"
                gradientUnits="objectBoundingBox">
                <Stop
                  offset="0"
                  stopColor={isRecordingFake ? fakeRecord : '#f9f9f9'}
                />
                <Stop offset="1" stopColor="#7d7d7d" />
              </RadialGradient>
              {/* <filter
          id="Ellipse_2"
          x="0"
          y="0"
          width="88"
          height="88"
          filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.349" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter> */}
            </Defs>
            <G id="Component_8_1" transform="translate(6 6)">
              <G
                transform="matrix(1, 0, 0, 1, -6, -6)"
                filter="url(#Ellipse_2)">
                {/* <G
                  id="Ellipse_2-2"
                  transform="translate(6 6)"
                  stroke={isRecording ? theme.colors.secondary : '#707070'}
                  strokeWidth="3"
                  fill="url(#radial-gradient)">
                  <Circle cx="35" cy="35" r="35" stroke="none" />
                  <Circle cx="35" cy="35" r="33.5" fill="none" />
                </G> */}
              </G>
              <Polygon
                points="50,0 0,100 100,100"
                stroke={isRecordingFake ? fakeRecord : '#707070'}
                strokeWidth={3}
                fill={isRecordingFake ? fakeRecord : 'url(#radial-gradient)'}
              />
            </G>
          </Svg>
        </ButtonContainer>
      )}
    </Pressable>
  );
}
