import { sVideoSession } from '../session';
import { initLocalStream } from '../video';
import { RamirezBase } from './ramirez.common';

/**
 *
 * @param {RTCRtpSender[]} senders
 * @param {(s:MediaStream)=>void} videoTrackCallback
 * @param {(s:MediaStream)=>void} audioTrackCallback
 * @returns {[hasVideo: boolean, hasAudio: boolean]}
 */
function getSenderFeatures(senders, videoTrackCallback, audioTrackCallback) {
  let hasAudio = false,
    hasVideo = false;
  senders.forEach((s) => {
    const audio = s.track.kind === 'audio';
    const video = s.track.kind === 'video';
    hasAudio = hasAudio || audio;
    hasVideo = hasVideo || video;
    if (audio && audioTrackCallback) audioTrackCallback(s.track);
    if (video && videoTrackCallback) videoTrackCallback(s.track);
  });
  return [hasVideo, hasAudio].map(Boolean);
}

const disableTracksCallback = (track) => (track.enabled = false);

export class Ramirez extends RamirezBase {
  /**
   *
   * @param {string} name
   * @param {(code:number, msg:import('../session').ErsoVTMessage)=>Promise<void>} enqueue
   */
  constructor(name, enqueue) {
    super(
      name,
      enqueue,
      getSenderFeatures,
      'getSenders',
      disableTracksCallback
    );
  }

  async initStream() {
    const { videoOn, micOn } = sVideoSession.state;
    const [videoStream, audioStream] = await initLocalStream();
    const senders = this.pc.getSenders();
    const [hasVideo, hasAudio] = getSenderFeatures(senders);
    console.log('hasVideo, hasAudio', hasVideo, hasAudio);

    if (senders.length > 0 && false === (hasAudio || hasAudio))
      senders.forEach((s) => this.pc.removeTrack(s.track));

    if (false === hasAudio) {
      console.log(this.name + ' adding AUDIO stream');
      this.pc.addTrack(audioStream.getAudioTracks()[0], audioStream);
    }
    if (false === hasVideo && videoOn) {
      console.log(this.name + ' adding VIDEO stream');
      this.pc.addTrack(videoStream.getVideoTracks()[0], videoStream);
    }

    let videoTrack;
    if (
      hasVideo &&
      videoOn &&
      false === (videoTrack = videoStream.getVideoTracks()[0]).enabled
    ) {
      videoTrack.enabled = true;
    }

    let audioTrack;
    if (
      hasAudio &&
      micOn &&
      false === (audioTrack = audioStream.getAudioTracks()[0]).enabled
    ) {
      audioTrack.enabled = true;
    }
  }

  _disableTracks(audioTrueVideoFalse = true) {
    console.log(`muting ${audioTrueVideoFalse ? 'audio' : 'video'}`);
    const senders = this.pc.getSenders();
    const args = [senders];
    if (false === audioTrueVideoFalse)
      args.push(disableTracksCallback, undefined);
    else args.push(undefined, disableTracksCallback);

    getSenderFeatures(...args);
  }

  registerForStreamEvent() {
    this.pc.ontrack = (e) => {
      e.streams.forEach((stream) => this.handleIncomingStream(stream));
    };
  }
}
