// recorder for web
import { ErsoRecorder } from './recorder.common';
import { ManualResetEvent } from './manualResetEvent';

function getSupportedMimeTypes() {
  const VIDEO_TYPES = ['webm', 'ogg', 'mp4', 'x-matroska'];
  const VIDEO_CODECS = [
    'vp9',
    'vp9.0',
    'vp8',
    'vp8.0',
    'avc1',
    'av1',
    'h265',
    'h.265',
    'h264',
    'h.264',
    'opus'
  ];

  const supportedTypes = [];
  VIDEO_TYPES.forEach((videoType) => {
    const type = `video/${videoType}`;
    VIDEO_CODECS.forEach((codec) => {
      const variations = [
        `${type};codecs=${codec}`,
        `${type};codecs:${codec}`,
        `${type};codecs=${codec.toUpperCase()}`,
        `${type};codecs:${codec.toUpperCase()}`,
        `${type}`
      ];
      variations.forEach((variation) => {
        if (MediaRecorder.isTypeSupported(variation))
          supportedTypes.push(variation);
      });
    });
  });
  return supportedTypes;
}
let bestMime = null;
async function requestRecorder(audio, video) {
  if (!bestMime) {
    const supportedMimeTypes = getSupportedMimeTypes();
    bestMime = supportedMimeTypes[0];
    console.log('Best supported mime types by priority : ', bestMime);
  }

  const stream = await navigator.mediaDevices.getUserMedia({
    audio,
    video
  });

  return new MediaRecorder(stream, { mimeType: bestMime });
}

export class ErsoRecorder_Web extends ErsoRecorder {
  async init(audio, video) {
    const change = this.audio ^ audio || this.video ^ video;
    this.audio = audio;
    this.video = video;

    if (change) {
      console.log('WebRecorder received new config!');
      this.resetRecorder();
      this.mediaRecorder = await requestRecorder(audio, video);
      this.mediaRecorder.addEventListener('dataavailable', (event) => {
        if (event.data && event.data.size > 0) {
          this.recordingChunks.push(event.data);
        }
      });
    }
  }

  resetRecorder() {
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      console.log('resetRecorder resets a running recorder!');
      this.mediaRecorder.stop();
      this.recordingChunks = [];
    }
  }

  async start(audio, video) {
    await this.init(audio, video);
    this.resetRecorder();
    this.recordingChunks = [];
    this.mediaRecorder.start();
  }

  /**
   *
   * @param {FormData} data
   */
  async stopAndPopulateFormData(data) {
    if (!this.mediaRecorder || this.mediaRecorder.state !== 'recording') {
      console.warn('STOP RECORDING - NO ACTIVE RECORDING');
      return;
    }

    const mre = new ManualResetEvent();
    const handler = (e) => {
      mre.release(this.recordingChunks);
    };

    this.mediaRecorder.addEventListener('stop', handler);
    this.mediaRecorder.stop();

    await mre.wait();
    this.mediaRecorder.removeEventListener('stop', handler);

    const blob = new Blob(this.recordingChunks, {
      type: this.recordingChunks[0].type
    });
    // const audioURL = URL.createObjectURL(audioData.data);

    data.append('video', blob);
    data.append('type', 'video');
    data.append('codec', this.recordingChunks[0].type);

    // //    download locally
    // var url = URL.createObjectURL(blob);
    // var a = document.createElement('a');
    // document.body.appendChild(a);
    // a.style = 'display: none';
    // a.href = url;
    // a.download = 'test.webm';
    // a.click();
    // window.URL.revokeObjectURL(url);

    // this.mediaRecorder = undefined;
    this.recordingChunks = [];
  }
}

export const recorder = new ErsoRecorder_Web();
export const makeRecorder = () => new ErsoRecorder_Web();
