import { createAPIImplementation } from '@horos/resi-common/create-api';

export default createAPIImplementation('connect', {
  /**
   *
   * @param {number} code
   * @returns {Promise<import('../models/standard').StandardResult<{type: 'operator' | 'patient'}>>}
   */
  connect(code) {}
});

export const plugsMap = JSON.parse('{"API":{},"connect":{}}');
