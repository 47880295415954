export const { mediaDevices } = navigator;

/**
 *
 * @param {RTCConfiguration} config
 * @returns
 */
export function makeNewConnection(config) {
  return new RTCPeerConnection(config);
}
