import { createAPIImplementation } from '@horos/resi-client/create-api';

export default createAPIImplementation(
  'patient',
  class {
    enqueue(code, msg) {}

    dequeue(code) {}

    /**
     *
     * @param {{sessionId: number}} data
     */
    submit_recording(data) {}

    log(message, patient) {}
  }
);

export const plugsMap = JSON.parse(
  '{"API":{"__with_autorization":true},"enqueue":{},"dequeue":{},"submit_recording":{"__custom_request_body":true,"__custom_headers":{"Content-Type":"multipart/form-data"},"__prepend_middleware":true},"log":{}}'
);
