import React from 'react';
import DraggableWeb from 'react-draggable';

// const normalBounds = { top: -200, bottom: 200, left: -400, right: 100 };

export function Draggable({ children, minX, minY, maxX, maxY, x, y }) {
  console.log('Web Drag Props', { minX, minY, maxX, maxY, x, y });
  return (
    <DraggableWeb
      style={{ backgroundColor: 'red' }}
      defaultPosition={{ x: 0, y: 0 }}
      position={null}
      //   grid={[25, 25]}
      scale={1}
      //   bounds={{ top: minY, bottom: maxY, left: minX, right: maxX }}
      // bounds={ false === fullScreen && normalBounds }
    >
      {children}
    </DraggableWeb>
  );
}
