import React from 'react';
import { Svg, Path, Rect, Polygon } from '../Svg';
import { Pressable } from 'react-native';
import { useEhState } from '@horos/eh-react-hooks';
import { InternalEnqueue, MSG, sVideoSession } from '../../lib/session';
import { sTheme } from '../../lib/theme';
import styled from 'styled-components/native';
// import InCallManager from 'react-native-incall-manager';

const PressableContainer = styled(Pressable)`
  width: 30px;
  height: 30px;
`;

export function VideoButton({}) {
  const { videoOn } = useEhState(sVideoSession);
  const {
    theme: {
      colors: { main, secondary }
    }
  } = useEhState(sTheme);

  const color = videoOn ? main : secondary;
  return (
    <PressableContainer
      onPress={() => {
        const newVal = Boolean(!videoOn);
        // InCallManager.set(newVal);
        sVideoSession.fire({ videoOn: newVal });
        InternalEnqueue(newVal ? MSG.video_on() : MSG.video_off());
      }}>
      <Svg
        enableBackground="new 0 0 50 50"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 50 50">
        <Rect fill="none" height="50" width="50" />
        <Polygon
          stroke={color}
          fill={color}
          points="49,14 36,21 36,29   49,36 "
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <Path
          d="M36,36c0,2.209-1.791,4-4,4  H5c-2.209,0-4-1.791-4-4V14c0-2.209,1.791-4,4-4h27c2.209,0,4,1.791,4,4V36z"
          stroke={color}
          fill={color}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </Svg>
    </PressableContainer>
  );
}
