import React, { useMemo, useState } from 'react';
import { useEhState, withEhStates } from '@horos/eh-react-hooks';
import styled from 'styled-components/native';
import { WebRTCVideo } from '../components/WebRTCVideo';
import { sVideoSession } from '../lib/session';
import { CallStatus } from './CallStatus';
import { sTheme } from '../lib/theme';
import { sDimensions } from '../style/responsive';
import { ExpandButton } from './buttons/Expand';
import { SpeakerToggler } from './buttons/Speaker';
import { ResetButton } from './buttons/Reset';
import { MicButton } from './buttons/Mic';
import { VideoButton } from './buttons/Video';
import { HangUp } from './buttons/HangUp';
import { Platform } from 'react-native';
import { Draggable } from './Draggable';

export const VIDEO_PW_DIM = ({ pw, ph, dimension }, num = false) => {
  const byH = ph(70, 0, 300, true);
  const byW = pw(90, 0, 300, true);

  const val = Math.min(byH, byW);
  console.log('val', val);
  return num ? val : `${val}px`;
};

const Container = withEhStates(
  styled.View`
    width: ${VIDEO_PW_DIM};
    /* max-height: 85%; */
    /* height: 100%; */
    /* flex-grow: 1; */
    /* height: ${VIDEO_PW_DIM}; */
    /* align-self: center; */
    align-items: center;

    /* background-color: yellow; */
  `,
  sDimensions
);

const FullScreenContainer = withEhStates(
  styled.View`
    width: ${({ pw }) => pw(100)};
    /* height: ${({ ph }) => ph(95)}; */
    flex-grow: 1;

    background-color: pink;
  `,
  sDimensions
);

const ControlsContainer = styled.View`
  /* height: ${({ fullScreen }) => (fullScreen ? 'auto' : '50px')}; */
  height: 100%;
  flex-direction: row;
  /* justify-content: space-evenly; */
  /* background-color: ${({ fullScreen }) =>
    fullScreen ? 'transparent' : 'rgba(0, 0, 0, 0.4)'}; */
  /* background-color: rgba(0, 0, 0, 0.4); */
  /* background-color: pink; */
`;

const LocalStreamArea = styled.View`
  width: 100%;
  width: 67%;
  left: 33%;
  position: absolute;
  flex-grow: 1;
  z-index: 2;
  justify-content: flex-end;
  align-items: flex-end;
  /* background-color: fuchsia; */
`;

const VideoControlsContainer = styled.View`
  height: 100%;
  max-height: 50px;
  /* width: 50%; */
  /* width: 100%; */
  flex-grow: 4;
  padding: 5px 0;

  justify-content: space-evenly;
  align-items: center;
  /* padding: 10px; */

  flex-direction: row;

  align-self: flex-end;

  background-color: rgba(0, 0, 0, 0.4);
  /* background-color: green; */
`;

const ExternalControlsContainer = styled.View`
  height: 100%;
  max-height: 100%;
  /* flex-grow: 1; */
  width: 33%;

  justify-content: flex-end;
  align-items: center;

  /* background-color: yellow; */
`;

const VideoContainer = withEhStates(
  styled.View`
    /* height: ${({ fullScreen }) => (fullScreen === true ? '100%' : '300px')};
  width: ${({ fullScreen }) => (fullScreen === true ? '100%' : '300px')}; */
    width: 100%;
    height: ${(props) =>
      props.fullScreen === true ? '100%' : VIDEO_PW_DIM(props)};
    background-color: black;
  `,
  sDimensions
);

const LocalVideoContainer = withEhStates(
  styled.View`
    height: 120px;
    width: 90px;
    background-color: black;
    margin-bottom: 20px;
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.main};
  `,
  sTheme
);

function bigScreenURL(
  localVideoStream,
  remoteVideoStream,
  swap,
  localStreamOnBigScreenUntilConnection,
  localVideoOn,
  remoteVideoOn
) {
  if (
    localStreamOnBigScreenUntilConnection &&
    !remoteVideoStream &&
    localVideoStream
  ) {
    return localVideoOn && localVideoStream;
  }
  if (remoteVideoStream && swap === false) {
    return remoteVideoOn ? remoteVideoStream : localVideoOn && localVideoStream;
  }
  if (localVideoStream && swap === true) {
    return localVideoOn && localVideoStream;
  }
  return undefined;
}

function smallScreenURL(
  localVideoStream,
  remoteVideoStream,
  swap,
  localStreamOnBigScreenUntilConnection,
  localVideoOn,
  remoteVideoOn
) {
  if (
    (localStreamOnBigScreenUntilConnection === true && !remoteVideoStream) ||
    false === remoteVideoOn
  )
    return undefined;
  if (remoteVideoStream && swap)
    return remoteVideoOn ? remoteVideoStream : undefined;
  if (localVideoStream && swap === false)
    return localVideoOn && localVideoStream;
  return undefined;
}

const streamMapper = Platform.OS === 'web' ? (x) => x : (x) => x && x.toURL();

const bigVideoNormalScreenStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%'
};

const bigVideoFullScreenStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  left: 0
};

const bigVideoFullScreenWithChildStyle = {
  ...bigVideoFullScreenStyle,

  width: '67%',
  left: '33%'
};

/**
 *
 * @param {{showLocal:boolean, bare:boolean, forceState: string}} param0
 * @returns
 */
export function VideoCall({
  showLocal,
  bare,
  children,
  forceState,
  localStreamOnBigScreenUntilConnection = true,
  user,
  disableVideoControls = false
}) {
  const {
    localVideoStream,
    localAudioStream,
    remoteVideoStream,
    remoteAudioStream,
    fullScreen,
    isRecording,
    renderKey,
    videoOn,
    videoOnRemote,
    webrtcStatus
  } = useEhState(sVideoSession);

  console.log('streams', {
    localVideoStream,
    localAudioStream,
    remoteVideoStream,
    remoteAudioStream
  });
  // console.log(
  //   '******************!!!!!!!!!!!!!! streams !!!!!!!!!!!!!!******************',
  //   { localStream, remoteStream }
  // );
  const { theme } = useEhState(sTheme);
  const sdim = useEhState(sDimensions);

  const [swap, setSwap] = useState(false);

  const status = useMemo(() => {
    if (localAudioStream && !remoteAudioStream) return 'connecting';
    else if (localAudioStream && remoteAudioStream) {
      return isRecording ? 'recording' : webrtcStatus;
    } else return 'idle';
  }, [localAudioStream, remoteAudioStream, isRecording, webrtcStatus]);

  const [localVideoStreamURL, remoteVideoStreamURL, remoteAudioStreamURL] =
    useMemo(() => {
      return [localVideoStream, remoteVideoStream, remoteAudioStream].map(
        streamMapper
      );
    }, [localVideoStream, remoteVideoStream, remoteAudioStream]);

  // console.log(user + ' Video Call Streams', {
  //   localVideoStreamURL,
  //   remoteVideoStreamURL,
  //   remoteAudioStreamURL
  // });
  const pwHeightDim = useMemo(() => VIDEO_PW_DIM(sdim, true), [sdim]);

  const FinalContainer = fullScreen ? FullScreenContainer : Container;

  const pw100 = sdim.pw(100, null, null, true);
  const ph100 = sdim.ph(100, null, null, true);

  const [bigURL, smallURL] = useMemo(
    () =>
      [bigScreenURL, smallScreenURL].map((f) =>
        f(
          localVideoStreamURL,
          remoteVideoStreamURL,
          swap,
          localStreamOnBigScreenUntilConnection,
          videoOn,
          videoOnRemote
        )
      ),
    [
      localVideoStreamURL,
      remoteVideoStreamURL,
      swap,
      localStreamOnBigScreenUntilConnection,
      videoOn,
      videoOnRemote
    ]
  );

  console.log(user + ' Video Call', {
    bigURL,
    smallURL,
    videoOnRemote
  });
  return (
    <FinalContainer>
      {!fullScreen && !bare && (
        <CallStatus
          status={forceState || status}
          style={{ marginBottom: 5, alignSelf: 'flex-start' }}
        />
      )}
      <VideoContainer fullScreen={fullScreen}>
        {bigURL && (
          <WebRTCVideo
            // key={renderKey}
            style={
              // if full screen
              fullScreen
                ? children // if has children
                  ? bigVideoFullScreenWithChildStyle
                  : // if no children
                    bigVideoFullScreenStyle
                : // if not full screen
                  bigVideoNormalScreenStyle
            }
            objectFit="contain"
            streamURL={bigURL}
            mirror={bigURL === localVideoStreamURL}
            // webMute={true}
          />
        )}

        {remoteAudioStreamURL && (
          <WebRTCVideo
            style={{
              position: 'absolute',
              width: 0,
              height: 0
            }}
            streamURL={remoteAudioStreamURL}
            // webMute
          />
        )}
        <LocalStreamArea>
          {smallURL && (
            <Draggable
              onShortPressRelease={() => setSwap(!swap)}
              minX={0}
              minY={0}
              maxX={fullScreen ? pw100 : pwHeightDim}
              maxY={fullScreen ? ph100 : pwHeightDim - 30}
              x={fullScreen ? pw100 - 90 : pwHeightDim - 90}
              y={fullScreen ? ph100 - 220 : pwHeightDim - 190}>
              <LocalVideoContainer>
                <WebRTCVideo
                  key={renderKey}
                  style={{
                    width: '100%',
                    height: '100%'
                  }}
                  objectFit="contain"
                  streamURL={smallURL}
                  mirror={smallURL === localVideoStreamURL}
                  // webMute={smallURL === localVideoStreamURL}
                  // webMute={true}
                />
              </LocalVideoContainer>
            </Draggable>
          )}
        </LocalStreamArea>
        {!bare && (
          <ControlsContainer fullScreen={fullScreen}>
            {children && (
              <ExternalControlsContainer>{children}</ExternalControlsContainer>
            )}
            {false === disableVideoControls && (
              <VideoControlsContainer withChild={children}>
                <VideoButton />
                <MicButton />
                <ResetButton color={theme.colors.main} />
                <SpeakerToggler />
                <ExpandButton color={theme.colors.main} />
                <HangUp color={theme.colors.error} />
              </VideoControlsContainer>
            )}
          </ControlsContainer>
        )}
      </VideoContainer>
    </FinalContainer>
  );
}
