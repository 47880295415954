import React from 'react';
import styled from 'styled-components/native';
import { Pressable } from 'react-native';
import { Svg, Defs, RadialGradient, Stop, G, Circle, Filter } from '../Svg';
import { styledShadow } from '../../lib/styled-shadow-cx';
import { useEhState, withEhStates } from '@horos/eh-react-hooks';
import { sSession, sVideoSession } from '../../lib/session';
import { sTheme } from '../../lib/theme';
import { startRecording, stopRecording } from '../../lib/record';
import { makeRsp, plugRsp, sDimensions } from '../../style/responsive';

const ButtonDim = makeRsp('height', plugRsp('global', 60, 90, 40, 71));
const ButtonContainer = withEhStates(
  styled.View`
    /* background-color: blue; */
    height: ${ButtonDim};
    width: ${ButtonDim};

    border-radius: 35px;

    ${({ pressed }) => styledShadow(pressed ? 0 : 8)};
  `,
  sDimensions
);

const chooseColor = (isRecording, recordingAllowed, theme, custom) => {
  if (isRecording) return theme.colors.secondary;
  if (false === recordingAllowed) return 'blue';
  else return custom;
};

export function RecordButton() {
  const {
    isRecording,
    currentRecording,
    recordingAllowed,
    recordAudio,
    recordVideo
  } = useEhState(sVideoSession);
  const { code } = useEhState(sSession);
  const { theme } = useEhState(sTheme);

  if (false === (recordAudio || recordVideo)) return null;

  return (
    <Pressable
      onPress={() => {
        const op = isRecording === true ? stopRecording : startRecording;
        op(code, currentRecording);
      }}>
      {({ pressed }) => (
        <ButtonContainer pressed={pressed}>
          <Svg
            //   width="88"
            //   height="88"
            viewBox="6 6 70 70"
            preserveAspectRatio="xMinYMin slice">
            <Defs>
              <RadialGradient
                id="radial-gradient"
                cx="0.5"
                cy="0.5"
                r="1.212"
                gradientTransform="translate(-0.171 0.278) rotate(-26.696)"
                gradientUnits="objectBoundingBox">
                <Stop offset="0" stopColor="#f9f9f9" />
                <Stop offset="1" stopColor="#7d7d7d" />
              </RadialGradient>
              <Filter
                id="Ellipse_2"
                x="0"
                y="0"
                width="88"
                height="88"
                filterUnits="userSpaceOnUse">
                <feOffset dx="3" dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodOpacity="0.349" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </Filter>
            </Defs>
            <G id="Component_8_1" transform="translate(6 6)">
              <G
                transform="matrix(1, 0, 0, 1, -6, -6)"
                filter="url(#Ellipse_2)">
                <G
                  id="Ellipse_2-2"
                  transform="translate(6 6)"
                  stroke={chooseColor(
                    isRecording,
                    recordingAllowed,
                    theme,
                    '#707070'
                  )}
                  strokeWidth="3"
                  fill="url(#radial-gradient)">
                  <Circle cx="35" cy="35" r="35" stroke="none" />
                  <Circle cx="35" cy="35" r="33.5" fill="none" />
                </G>
              </G>
              <Circle
                id="Ellipse_3"
                cx="7.5"
                cy="7.5"
                r="7.5"
                transform="translate(28 28)"
                fill={chooseColor(
                  isRecording,
                  recordingAllowed,
                  theme,
                  '#363636'
                )}
              />
            </G>
          </Svg>
        </ButtonContainer>
      )}
    </Pressable>
  );
}
