import { makeResiClient } from '../resi-client/apis';

const URLS = {
  ORAN: 'http://192.168.0.102:9095',
  MAC: 'http://192.168.1.20:9095',
  ANDROID: 'http://192.168.43.104:9095',
  PROD: 'https://backend.oragolan.video'
};

// export const RESI_URL = Platform.OS === 'web' ? URLS.LOCAL_WEB : URLS.ORAN;

export const RESI_URL = URLS.PROD;

export const resi = makeResiClient(RESI_URL);

export function resiStandardResponseHandler(res) {
  if (!res) console.error('Network error');
  else if (false === res.success)
    console.error('Error from server: ', res.error);
  else return res.data;
  return undefined;
}
