import React from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { Svg, G, Line } from '../Svg';
import { useEhState } from '@horos/eh-react-hooks';
import { sVideoSession } from '../../lib/session';

function defaultOnPress(fullScreen) {
  sVideoSession.fire({ fullScreen: Boolean(!fullScreen) });
}

const PressableContainer = styled(Pressable)`
  width: 30px;
  height: 30px;
`;

/**
 *
 * @param {{color: string, onPress:(event:import('react-native').GestureResponderEvent) => void}}} param0
 * @returns
 */
export function ExpandButton({ color = '#f9f9f9', onPress, lineWidth = 5 }) {
  const { fullScreen } = useEhState(sVideoSession);
  return (
    <PressableContainer onPress={onPress || (() => defaultOnPress(fullScreen))}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 27 27">
        <G
          id="Component_2_4"
          data-name="Component 2 – 4"
          transform="translate(0.5 0.5)">
          <Line
            id="Line_1"
            data-name="Line 1"
            x2="10"
            transform="translate(0 0.5)"
            fill="none"
            stroke={color}
            strokeWidth={lineWidth}
          />
          <Line
            id="Line_2"
            data-name="Line 2"
            x2="10"
            transform="translate(0.5) rotate(90)"
            fill="none"
            stroke={color}
            strokeWidth={lineWidth}
          />
          <Line
            id="Line_3"
            data-name="Line 3"
            x1="10"
            transform="translate(16 0.5)"
            fill="none"
            stroke={color}
            strokeWidth={lineWidth}
          />
          <Line
            id="Line_4"
            data-name="Line 4"
            x2="10"
            transform="translate(25.5) rotate(90)"
            fill="none"
            stroke={color}
            strokeWidth={lineWidth}
          />
          <Line
            id="Line_5"
            data-name="Line 5"
            x2="10"
            transform="translate(0 25.5)"
            fill="none"
            stroke={color}
            strokeWidth={lineWidth}
          />
          <Line
            id="Line_6"
            data-name="Line 6"
            x2="10"
            transform="translate(0.5 26) rotate(-90)"
            fill="none"
            stroke={color}
            strokeWidth={lineWidth}
          />
          <Line
            id="Line_7"
            data-name="Line 7"
            x1="10"
            transform="translate(16 25.5)"
            fill="none"
            stroke={color}
            strokeWidth={lineWidth}
          />
          <Line
            id="Line_8"
            data-name="Line 8"
            x2="10"
            transform="translate(25.5 26) rotate(-90)"
            fill="none"
            stroke={color}
            strokeWidth={lineWidth}
          />
        </G>
      </Svg>
    </PressableContainer>
  );
}
