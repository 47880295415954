import { withEhStates } from '@horos/eh-react-hooks';
import styled from 'styled-components/native';
import { makeRsp, plugRsp, sDimensions } from '../responsive';

export const mainTheme = {
  colors: {
    main: '#53B5AB',
    background: '#EEEEEE',
    secondary: '#F10840',
    header: '#262628',
    text: '#9B9B9B',
    placeholder: '#ABABAB',
    error: '#DD1010',
    fakeRecord: '#5302d6'
  },
  layout: {
    paddingHorizontal: '20px',
    paddingTop: '20px'
  }
};

/**
 *
 * @param {keyof typeof mainTheme.colors} color
 * @returns {(props:{theme: typeof mainTheme}) => string}
 */
export const styledColor =
  (color) =>
  ({ theme }) =>
    theme.colors[color];

/**
 *
 * @param {import('../responsive').RspFunction} rspFunc
 * @returns {import('react-native').TextComponent}
 */
const makeFontSizeStandard = (rspFunc) =>
  withEhStates(
    styled.Text`
      font-size: ${rspFunc};
      /* background-color: green; */
    `,
    sDimensions
  );

export const H1 = makeFontSizeStandard(
  makeRsp('height', plugRsp('mobile', 35, 50, 20, 36))
);

export const H2 = makeFontSizeStandard(
  makeRsp('height', plugRsp('mobile', 17, 27, 16, 22))
);

export const H3 = makeFontSizeStandard(
  makeRsp('height', plugRsp('mobile', 14, 16, 12, 18))
);

export const H4 = makeFontSizeStandard(
  makeRsp('height', plugRsp('mobile', 10, 14, 12, 20))
);

export const P = makeFontSizeStandard(
  makeRsp('height', plugRsp('mobile', 10, 20, 8, 16))
);
// export const withPadding = (Comp) => ({ ...props }) => styled(Comp)``;
