import React, { useEffect, useRef } from 'react';

/**
 *
 * @param {{streamURL:string, mirror: boolean, style: import('react-native').ViewStyle}} param0
 * @returns
 */
export function WebRTCVideo({ streamURL, mirror, webMute, style = {} }) {
  console.log('WebRTCVideo streamURL', streamURL);
  /** @type {React.LegacyRef<HTMLVideoElement>} */
  const videoRef = useRef();

  useEffect(() => {
    console.log('Setting video object!', { vr: videoRef.current, streamURL });
    // videoRef.current.setAttribute('autoplay', '');
    // videoRef.current.setAttribute('muted', '');
    // videoRef.current.setAttribute('playsinline', '');
    videoRef.current.srcObject = streamURL;
  }, [streamURL]);

  return (
    <video
      // playsinline
      muted={webMute}
      autoPlay
      playsInline
      ref={videoRef}
      style={{
        ...style,
        transform: mirror ? 'rotateY(180deg)' : 'none',
        background: 'transparent'
      }}
      // src={ streamURL.id }
      // srcObject={streamURL}
    />
  );
}
