import { EhState } from '@horos/eh';
import { useEhState } from '@horos/eh-react-hooks';
import React from 'react';
import styled from 'styled-components/native';
import { Modal, Pressable, Text } from 'react-native';
import { styledShadow } from '../lib/styled-shadow-cx';
import { ModalContainer } from './Modal';

export const sStageModal = EhState.fromInitialState({
  on: false,
  options: [],
  color: 'white',
  /**
   *
   * @param {string} value
   * @param {number} idx
   */
  onUpdate: (value, idx) => {}
});

const OptionPressable = styled(Pressable)`
  height: 40px;
  width: 200px;
  justify-content: center;
  align-items: center;

  background-color: ${({ color }) => color};
  margin-vertical: 5px;
  ${styledShadow(5)};
  border-radius: 15px;
`;

export function StageModal() {
  const { on, options, color, onUpdate } = useEhState(sStageModal);

  return (
    <Modal visible={on} animationType="fade" transparent={true}>
      <ModalContainer
        contentContainerStyle={{
          alignItems: 'center',
          minHeight: '100%',
          justifyContent: 'center'
        }}>
        {options?.map((option, idx) => (
          <OptionPressable
            key={idx}
            color={color}
            onPress={() => {
              console.log('updating stage to:', option);
              sStageModal.fire({ on: false });
              onUpdate(option, idx);
            }}>
            <Text style={{ color: 'white' }}>{option}</Text>
          </OptionPressable>
        ))}
      </ModalContainer>
    </Modal>
  );
}
