import { useEhState } from '@horos/eh-react-hooks';
import React from 'react';
import { Pressable, Share } from 'react-native';
import { Svg, Circle, Line } from '../Svg';
import { sTheme } from '../../lib/theme';

export function ShareButton({ code }) {
  const { theme } = useEhState(sTheme);
  return (
    <Pressable
      onPress={() => {
        Share.share(
          {
            // url: `https://www.oragolan.video/?${code}`,
            message: `https://www.oragolan.video/?${code}`,
            title: 'Connect to an Erso VT Session'
          },
          {
            subject: 'Connect to an Erso VT Session',
            dialogTitle: 'Send an Erso VT Session Link',
            tintColor: theme.colors.main
          }
        );
      }}>
      <Svg
        fill="none"
        height="24"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg">
        <Circle cx="18" cy="5" r="3" />
        <Circle cx="6" cy="12" r="3" />
        <Circle cx="18" cy="19" r="3" />
        <Line x1="8.59" x2="15.42" y1="13.51" y2="17.49" />
        <Line x1="15.41" x2="8.59" y1="6.51" y2="10.49" />
      </Svg>
    </Pressable>
  );
}
