import React from 'react';
import styled from 'styled-components/native';

const Container = styled.View`
  justify-content: space-between;
  align-items: center;
  /* height: 200px; */
  /* background-color: blue; */
  flex-grow: 1;
`;

const ControlContainer = styled.View`
  flex-grow: 1;
  justify-content: space-evenly;
  /* background-color: blue; */
`;

const HorizontalContainer = styled.View`
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
`;

const Title = styled.Text`
  font-weight: bold;
  /* margin-bottom: 20px; */
`;

/**
 *
 * @param {{name:string, children:import('react').ReactElement[], horizontal: boolean, style: import('react-native').StyleProp<import('react-native').ViewStyle>}} param0
 * @returns
 */
export function ControllerContainer({ name, children, horizontal, style }) {
  const InteralContainer = horizontal ? HorizontalContainer : ControlContainer;
  return (
    <Container style={style}>
      <Title>{name}</Title>
      <InteralContainer>{children}</InteralContainer>
    </Container>
  );
}
