import React from 'react';
import { View } from 'react-native';

/**
 *
 * @param {{width: number, height: number}} param0
 * @returns
 */
export function Margin({ width = 10, height = 10 }) {
  return <View style={{ width, height }} />;
}

export function FlexGrower({ flexGrow = 1 }) {
  return <View style={{ flexGrow }} />;
}
