import { Recording } from '../resi-client/models/recording';
import { resi, resiStandardResponseHandler } from './resi';
import { sVideoSession } from './session';

export async function startRecording(code, recording) {
  const res = await resi.operator.create_recording(code, recording);
  const data = resiStandardResponseHandler(res);
  console.log('startRecording', { data, res });
  if (data) {
    sVideoSession.fire({
      currentRecording: Object.assign(new Recording(), recording, { id: data })
    });
  }
}

export async function stopRecording(code) {
  sVideoSession.fire({ recordingAllowed: false });
  await resi.operator.stop_recording(code);
  // const newRecording = resiStandardResponseHandler(res);
  // if (newRecording) sVideoSession.fire({ currentRecording: newRecording });
}
