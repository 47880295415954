import React from 'react';
import styled from 'styled-components/native';
import { ErsoButton } from '../components/buttons/Button';
import { H1, H2 } from '../style/theme';
import RNExitApp from 'react-native-exit-app';
import { Platform } from 'react-native';

const Container = styled.View`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
`;

export function SeeYou() {
  return (
    <Container>
      <H2>This session has ended.</H2>
      <H1>See you next time!</H1>
      <ErsoButton
        onPress={() => {
          if (Platform.OS === 'web') window.location = '/';
          else RNExitApp.exitApp();
        }}
        mode="warning"
        text="Exit"
      />
    </Container>
  );
}
