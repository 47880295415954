import React from 'react';
import { Svg, G, Path } from '../Svg';
import { Pressable } from 'react-native';
import { useEhState } from '@horos/eh-react-hooks';
import { InternalEnqueue, MSG, sVideoSession } from '../../lib/session';
import { sTheme } from '../../lib/theme';
// import InCallManager from 'react-native-incall-manager';

export function MicButton({}) {
  const { micOn } = useEhState(sVideoSession);
  const {
    theme: {
      colors: { main, secondary }
    }
  } = useEhState(sTheme);

  const color = micOn ? main : secondary;
  return (
    <Pressable
      style={{ width: 30, height: 30 }}
      onPress={() => {
        const newVal = Boolean(!micOn);
        const mute = !newVal;
        // InCallManager.setMicrophoneMute(mute);
        sVideoSession.fire({ micOn: newVal });
        InternalEnqueue(true === mute ? MSG.audio_off() : MSG.audio_on());
      }}>
      <Svg
        id="Layer_1"
        // style="enable-background:new 0 0 512 512;"
        version="1.1"
        viewBox="0 0 512 512">
        <G>
          <Path
            stroke={color}
            fill={color}
            d="M256,353.5c43.7,0,79-37.5,79-83.5V115.5c0-46-35.3-83.5-79-83.5c-43.7,0-79,37.5-79,83.5V270   C177,316,212.3,353.5,256,353.5z"
          />
          <Path
            stroke={color}
            fill={color}
            d="M367,192v79.7c0,60.2-49.8,109.2-110,109.2c-60.2,0-110-49-110-109.2V192h-19v79.7c0,67.2,53,122.6,120,127.5V462h-73v18   h161v-18h-69v-62.8c66-4.9,117-60.3,117-127.5V192H367z"
          />
        </G>
      </Svg>
    </Pressable>
  );
}
