import { EhState } from '@horos/eh';
import { resi } from './resi';

const sPatientSearchDefaultState = () => ({
  searchResults: [],
  error: null
});

export const sPatientSearch = EhState.fromInitialState(
  sPatientSearchDefaultState()
);

export function resetPatientSearch() {
  sPatientSearch.fire(sPatientSearchDefaultState());
}

let submitTimeoutOn = false;
let lastSubmitValue = '';

async function submitSearchRequest() {
  const submittedValue = lastSubmitValue;
  submitTimeoutOn = false;
  const res = await resi.operator.search_patients(submittedValue);
  if (submittedValue !== lastSubmitValue) {
    // a newer request was issued
    return;
  }
  if (!res || res.error) {
    sPatientSearch.fire({ error: res.error });
  } else {
    sPatientSearch.fire({ searchResults: res.data });
  }
}

export function submitSearchTerm(term) {
  if (!term || term === '') {
    resetPatientSearch();
    return;
  }
  lastSubmitValue = term;
  if (false === submitTimeoutOn) {
    submitTimeoutOn = true;
    setTimeout(submitSearchRequest, 500);
  }
}
