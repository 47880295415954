import { createAPIImplementation } from '@horos/resi-client/create-api';

export default createAPIImplementation('operator', {
  /**
   *
   * @param {Patient} patient
   */
  continue_session(patient) {},

  /**
   *
   * @param {Patient} patient
   */
  new_session(patient) {},

  enqueue(code, msg) {},

  dequeue(code) {},

  /**
   * @param {number} code
   * @param {Recording} recording
   * @returns {Promise<import('../models/standard').StandardResult<Recording>>}
   */
  create_recording(code, recording) {},

  stop_recording(code) {},

  get_next_recording(code) {},

  /**
   *
   * @param {number} recordingId
   * @param {boolean} eloaded
   * @param {number} loadLevel
   * @returns {Promise<import('../models/standard').StandardResult<Recording>>}
   */
  attribute_recording(recordingId, eloaded, loadLevel) {},

  /**
   *
   * @param {string} name
   */
  create_patient(name) {},

  get_metadata() {},

  /**
   *
   * @param {string} searchTerm
   * @returns {Promise<import('../models/standard').StandardResult<Patient[]>>}
   */
  search_patients(searchTerm) {},

  /**
   *
   * @param {number} code
   * @param {boolean} use
   */
  set_use_for_research(code, use) {},

  log(message, session, patient) {}
});

export const plugsMap = JSON.parse(
  '{"API":{"__with_autorization":true},"continue_session":{},"new_session":{},"enqueue":{},"dequeue":{},"create_recording":{},"stop_recording":{},"get_next_recording":{},"attribute_recording":{},"create_patient":{},"get_metadata":{},"search_patients":{},"set_use_for_research":{},"log":{}}'
);
