import React from 'react';

export const Svg = ({ children, ...props }) => <svg {...props}>{children}</svg>;
export const Polygon = ({ children, ...props }) => (
  <polygon {...props}>{children}</polygon>
);
export const Defs = ({ children, ...props }) => (
  <defs {...props}>{children}</defs>
);
export const ClipPath = ({ children, ...props }) => (
  <clipPath {...props}>{children}</clipPath>
);
export const Rect = ({ children, ...props }) => (
  <rect {...props}>{children}</rect>
);
export const G = ({ children, ...props }) => <g {...props}>{children}</g>;
export const Path = ({ ...props }) => <path {...props} />;
export const Line = ({ children, ...props }) => (
  <line {...props}>{children}</line>
);
export const RadialGradient = ({ children, ...props }) => (
  <radialGradient {...props}>{children}</radialGradient>
);
export const Stop = ({ children, ...props }) => (
  <stop {...props}>{children}</stop>
);
export const Circle = ({ children, ...props }) => (
  <circle {...props}>{children}</circle>
);
export const LinearGradient = ({ children, ...props }) => (
  <linearGradient {...props}>{children}</linearGradient>
);

export const Filter = ({ children, ...props }) => (
  <filter {...props}>{children}</filter>
);
