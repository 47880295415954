import { makeResiImplementationFactory } from '@horos/resi-client';
import { applyPlugs } from '@horos/resi-client/plugs';

// Importing all APIs
import connect, { plugsMap as connect_plugs } from './connect.js';
import healthCheck, { plugsMap as healthCheck_plugs } from './healthCheck.js';
import operator, { plugsMap as operator_plugs } from './operator.js';
import patient, { plugsMap as patient_plugs } from './patient.js';

// Applying plugs to functions
applyPlugs(connect, connect_plugs);
applyPlugs(healthCheck, healthCheck_plugs);
applyPlugs(operator, operator_plugs);
applyPlugs(patient, patient_plugs);

// Actual API Object
export const resiAPIImplementation = {
  connect,
  healthCheck,
  operator,
  patient
};

// Helper function for creating a client with intellisense
export const makeResiClient = makeResiImplementationFactory(
  resiAPIImplementation
);
