import { withEhStates } from '@horos/eh-react-hooks';
import React, { useState } from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { sTheme } from '../lib/theme';

const Container = styled.View`
  /* min-height: 120px; */
  width: 80%;
  border-radius: 15px;
  elevation: 8;
  background-color: white;
  overflow: hidden;
`;

const EntryContainer = withEhStates(
  styled(Pressable)`
    height: 40px;
    width: 100%;

    padding-horizontal: 4%;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: ${({ pressed, theme, odd }) =>
      pressed ? theme.colors.main : theme.colors.background};
    /* background-color: ${({ odd }) =>
      odd ? 'rgba(0,0,0,0.15)' : 'white'}; */
  `,
  sTheme
);

const EntryText = withEhStates(
  styled.Text`
    color: ${({ theme, pressed }) =>
      pressed ? theme.colors.background : theme.colors.main};
    font-size: 20px;
  `,
  sTheme
);

function SearchResult({ patient, onSelected, odd }) {
  const [pressed, setPressed] = useState(false);
  const { name, id } = patient;
  return (
    <EntryContainer
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      onPress={() => onSelected(patient)}
      //   key={idx}
      odd={odd}
      pressed={pressed}>
      <EntryText pressed={pressed}>{name}</EntryText>
      <EntryText pressed={pressed}>{id}</EntryText>
    </EntryContainer>
  );
}

/**
 *
 * @param {import('react-native').ViewProps & {results: {name:string, id:number}[], onSelected: ({name:string, id:number}) => void}} param0
 * @returns
 */
export function SearchResults({ results, onSelected, ...props }) {
  return (
    <Container {...props}>
      {results &&
        results.map((patient, idx) => (
          <SearchResult
            odd={idx % 2 === 1}
            key={idx}
            patient={patient}
            onSelected={onSelected}
          />
        ))}
    </Container>
  );
}
