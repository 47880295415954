import { EhEvent, EhState } from '@horos/eh';
import { Recording } from '../resi-client/models/recording';
import { resi } from './resi';

/**
 * @typedef {{type: keyof MSG, payload: any}} ErsoVTMessage
 * @param {string} type
 * @param {any} payload
 * @returns {ErsoVTMessage}
 */
const msg = (type, payload) => ({ type, payload });
export const MSG = {
  reconnect: () => msg('reconnect'),
  ice_candidate: (candidate) => msg('ice_candidate', candidate),
  offer: (offer) => msg('offer', offer),
  answer: (answer) => msg('answer', answer),
  /** @param {boolean} record  */
  toggle_recording: (record) => msg('toggle_recording', record),
  toggle_record_video: (record) => msg('toggle_record_video', record),
  toggle_record_audio: (record) => msg('toggle_record_audio', record),
  /**
   *
   * @param {'Audio' | 'Video'} media
   * @param {boolean} on
   */
  record_media_request: (media, on) =>
    msg('record_media_request', { media, on }),
  /**
   *
   * @param {'Audio' | 'Video'} media
   * @param {boolean} confirmed
   */
  record_media_response: (media, confirmed) =>
    msg('record_media_response', { media, confirmed }),
  restart: () => msg('restart'),
  video_off: () => msg('video_off'),
  video_on: () => msg('video_on'),
  video_off_remote: () => msg('video_off_remote'),
  video_on_remote: () => msg('video_on_remote'),
  audio_off: () => msg('audio_off'),
  audio_on: () => msg('audio_on'),
  hang_up: () => msg('hang_up'),
  hang_up_remote: () => msg('hang_up_remote'),
  /** @param {boolean} activate  */
  toggle_recording_fake: (activate) => msg('toggle_recording_fake', activate),
  allow_recording: () => msg('allow_recording')
};

export const eInternalEnqueue = EhEvent.fromInstance(MSG.answer());
/**
 *
 * @param {ErsoVTMessage} message
 */
export function InternalEnqueue(message) {
  console.log('InternalEnqueue', message);
  eInternalEnqueue.fire(message);
}

/**@type {'connect' | 'patient' | 'operator'}  */
const session_type = null;

export const sSession = EhState.fromInitialState({
  code: 0,
  type: session_type || 'connect',
  operator_offer: null,
  patient_answer: null,
  connected: false,
  confirmed: false,
  reconnect: false,
  patient_id: 0,
  terminated: false,
  ice_servers: [],
  useForResearch: false
});

/**
 * @type {{
 * localVideoStream: import('react-native-webrtc').MediaStream,
 * localAudioStream: import('react-native-webrtc').MediaStream,
 * remoteVideoStream: import('react-native-webrtc').MediaStream,
 * remoteAudioStream: import('react-native-webrtc').MediaStream,
 * fullScreen: boolean,
 * isRecording: boolean | undefined,
 * currentRecording: Recording,
 * speakerOn: boolean,
 * micOn: boolean,
 * videoOn: boolean,
 * renderKey: 0,
 * recordAudio: 'unset' | 'pending' | 'confirmed' | 'denied',
 * recordVideo: 'unset' | 'pending' | 'confirmed' | 'denied',
 * videoOnRemote: boolean,
 * webrtcStatus: '',
 * recordingAllowed: boolean,
 * mediaRecordModal: boolean
 * }}
 */
const defaultSVideoSession = {
  localVideoStream: undefined,
  localAudioStream: undefined,
  remoteVideoStream: undefined,
  remoteAudioStream: undefined,
  fullScreen: false,
  isRecording: null,
  isRecordingFake: false,
  currentRecording: new Recording(),
  speakerOn: true,
  micOn: true,
  videoOn: true,
  renderKey: 0,
  videoOnRemote: true,
  webrtcStatus: '',
  recordingAllowed: true,
  recordAudio: 'unset',
  recordVideo: 'unset',
  mediaRecordModal: false
};
export const sVideoSession = EhState.fromInitialState(defaultSVideoSession);

/**
 *
 * @param {[]} internal_queue
 * @param {()=>void} handler
 */
async function execute_internal_queue(internal_queue, handler, cycle) {
  if (internal_queue.length === 0) return;
  const item = internal_queue.shift();
  const promiseOrVoid = handler(item, cycle);
  if (promiseOrVoid.then) await promiseOrVoid;
  return execute_internal_queue(internal_queue, handler);
}

/**
 * @typedef {{type:keyof MSG, payload:object}} DequeuerHandlerArgument
 * @typedef {(msg:DequeuerHandlerArgument, cycle: number | undefined) => Promise<void>} DequeuerHandler
 * @param {number} code
 * @param {'operator' | 'patient'} mode
 * @param {DequeuerHandler} handler
 */
export function dequeuer(code, mode, handler, cycle = 1) {
  if (sSession.state.code !== code) return;
  if (code === 0) return;
  if (cycle === 1)
    eInternalEnqueue.register((data) => {
      console.log('EXECUTING INTERNAL QUEUE HANDLER');
      handler(data, -1);
    });
  console.log('Dequeuer cycle', { code, mode, cycle });
  const next = () => dequeuer(code, mode, handler, cycle + 1);
  resi[mode]
    .dequeue(code)
    .then((res) => {
      // console.log('Dequeuer response', { code, mode });
      if (!res || res.error) {
        return new Promise((resolve) => setTimeout(resolve, 1000));
      }
      if (res.success) {
        const internal_queue = [].concat(res.data);
        return execute_internal_queue(internal_queue, handler, cycle);
      }
    })
    .then(() => {
      next();
    })
    .catch((e) => {
      console.error('NETWORK ERROR: ', e);
      setTimeout(next, 500);
    });
}
