import { useEhState, withEhStates } from '@horos/eh-react-hooks';
import { StatusBar } from 'react-native';
import React from 'react';
import styled from 'styled-components/native';
import { sSession, sVideoSession } from '../lib/session';
import { Header, HEADER_HEIGHT } from './Header';
import { sTheme } from '../lib/theme';
import { Platform } from 'react-native';
import { sDimensions } from '../style/responsive';

const BORDER_STATUS_HEIGHT = 20;

const AppFrameNative = styled.View`
  height: 100%;
  width: 100%;

  overflow: hidden;
`;

const AppFrameWeb = styled(AppFrameNative)`
  border-color: ${({ statusColor }) => statusColor};
  border-style: solid
  border-top-width: 20px;
`;

const AppFrame = Platform.OS === 'web' ? AppFrameWeb : AppFrameNative;

const AppContent = withEhStates(
  styled.View`
    width: 100%;
    height: ${({ ph, fullScreen }) => {
      let val =
        ph(100, null, null, true) -
        (StatusBar.currentHeight || BORDER_STATUS_HEIGHT);
      console.log('StatusBar', StatusBar);
      if (false === fullScreen) val -= HEADER_HEIGHT;
      return val;
    }}px;
    /* height: 100%; */
    /* flex-grow: 1; */

    overflow: auto;

    /* background-color: fuchsia; */
  `,
  sDimensions,
  sVideoSession
);

function pickColor(
  isRecording,
  isRecordingFake,
  type,
  main,
  secondary,
  fakeRecord
) {
  if (isRecording) return secondary;
  if (isRecordingFake) return type === 'operator' ? fakeRecord : secondary;
  else return main;
}

export function Frame({ children }) {
  const { fullScreen, isRecording, isRecordingFake } =
    useEhState(sVideoSession);

  const { type } = useEhState(sSession);

  const {
    theme: {
      colors: { main, secondary, fakeRecord }
    }
  } = useEhState(sTheme);

  // if recording, pick secondary. if fake recording, pick fakeRecord, if none pick main
  const backgroundColor = pickColor(
    isRecording,
    isRecordingFake,
    type,
    main,
    secondary,
    fakeRecord
  );

  return (
    <AppFrame nativeID="app-frame" statusColor={backgroundColor}>
      <StatusBar backgroundColor={backgroundColor} animated />
      {fullScreen === false && <Header />}
      <AppContent nativeID="app-content">{children}</AppContent>
    </AppFrame>
  );
}
