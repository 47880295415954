import { makeNewConnection } from '../webrtc';
import { MSG, sSession, sVideoSession } from '../session';
import { initLocalStream } from '../video';
import { Platform } from 'react-native';

/** @type {import('react-native-incall-manager').default} */
let InCallManager = null;
if (Platform.OS !== 'web')
  InCallManager = require('react-native-incall-manager').default;

const verifyMediaType = (media) => {
  if (false === ['Audio', 'Video'].includes(media))
    throw new Error('Media Type');
};
export class RamirezBase {
  /**
   *
   * @param {string} name
   * @param {(code:number, msg:import('../session').ErsoVTMessage)=>Promise<void>} enqueue
   */
  constructor(name, enqueue, getFeatures, getLocalsKey, disableTracksCallback) {
    this.name = name;
    this.enqueue = enqueue;
    this.getFeatures = getFeatures;
    this.getLocalsKey = getLocalsKey;
    this.disableTracksCallback = disableTracksCallback;
  }

  async start(code) {
    this.code = code;
    if (Platform.OS === 'web') return;

    InCallManager.start({ media: 'video' }); // or _DEFAULT_ or _DTMF_
    InCallManager.setSpeakerphoneOn(true);
  }

  createPeerConnection() {
    if (this.pc) {
      this.pc.close();
    }

    const { iceServers } = sSession.state;
    console.log('iceServers', iceServers);
    this.pc = makeNewConnection({ iceServers });

    this.pc.oniceconnectionstatechange = (e) => {
      console.log(`${this.name} State Change`, e.target.iceConnectionState);
      sVideoSession.fire({ webrtcStatus: e.target.iceConnectionState });

      if (
        ['disconnected', 'closed', 'failed'].includes(
          e.target.iceConnectionState
        )
      ) {
        sVideoSession.fire({ isRecording: false });
      }
    };
  }

  init() {
    this.createPeerConnection();
  }

  _disableTracks(audioTrueVideoFalse = true) {
    console.log(`muting ${audioTrueVideoFalse ? 'audio' : 'video'}`);
    const senders = this.pc[this.getLocalsKey]();
    const args = [senders];
    if (false === audioTrueVideoFalse)
      args.push(this.disableTracksCallback, undefined);
    else args.push(undefined, this.disableTracksCallback);

    this.getFeatures(...args);
  }

  disableVideo() {
    this._disableTracks(false);
  }

  disableAudio() {
    this._disableTracks();
  }

  handleIncomingStream(stream) {
    console.log(`******** ${this.name} GOT REMOTE ***********`, stream);
    if (stream.getTracks()[0].kind === 'audio')
      sVideoSession.fire({ remoteAudioStream: stream });
    if (stream.getTracks()[0].kind === 'video')
      sVideoSession.fire({ remoteVideoStream: stream });
  }

  hangUp() {
    this.pc.close();
    const streamsToRelease = [];

    if (sVideoSession.state.localAudioStream)
      streamsToRelease.push(sVideoSession.state.localAudioStream);
    // sVideoSession.state.localAudioStream.release();
    if (sVideoSession.state.localVideoStream)
      // sVideoSession.state.localVideoStream.release();
      streamsToRelease.push(sVideoSession.state.localVideoStream);

    if (Platform.OS !== 'web') {
      InCallManager.stop();
      streamsToRelease.forEach((s) => s.release());
    } else {
      streamsToRelease.forEach((s) => s.getTracks().forEach((t) => t.stop()));
    }

    sVideoSession.fire({
      localAudioStream: undefined,
      localVideoStream: undefined,
      remoteAudioStream: undefined,
      remoteVideoStream: undefined,
      recordAudio: 'unset',
      recordVideo: 'unset',
      fullScreen: false
    });
    sSession.fire({
      code: undefined,
      confirmed: false,
      patient_id: undefined,
      operator_offer: undefined,
      patient_answer: undefined,
      terminated: true
    });
  }

  resendRecordingSettings() {
    const { recordAudio, recordVideo } = sVideoSession.state;
    if (recordAudio === 'confirmed')
      this.enqueue(this.code, MSG.record_media_response('Audio', true));
    if (recordVideo === 'confirmed')
      this.enqueue(this.code, MSG.record_media_response('Video', true));
  }

  /**
   *
   * @param {import('../session').DequeuerHandlerArgument} param0
   */
  async commonHandler({ type, payload }) {
    let renderKey = sVideoSession.state.renderKey + 1;
    console.log(this.name + ' commonHandler', { type });
    switch (type) {
      case 'reconnect':
      case 'restart':
        if (type === 'restart') {
          this.enqueue(this.code, MSG.reconnect());
          this.resendRecordingSettings();
        }
        // InCallManager.stop();
        // await this.init();
        await this.start(this.code, true);
        return sVideoSession.fire({ renderKey });
      case 'video_off':
        console.log(this.name + ' video off');
        this.disableVideo();
        this.enqueue(this.code, MSG.video_off_remote());
        break;
      case 'video_on':
        console.log(this.name + ' video on');
        await this.initStream();
        return this.enqueue(this.code, MSG.video_on_remote());
      case 'video_off_remote':
        console.log(this.name + ' VIDEO_OFF_REMOTE');
        sVideoSession.fire({ renderKey, videoOnRemote: false });
        // sVideoSession.fire({ remoteVideoStream: undefined });
        break;
      case 'video_on_remote':
        console.log(this.name + ' VIDEO ON REMOTE');
        sVideoSession.fire({ renderKey, videoOnRemote: true });
        break;
      case 'audio_off':
        console.log(this.name + ' audio off');
        this.disableAudio();
        break;
      case 'audio_on':
        console.log(this.name + ' audio on');
        await this.initStream();
        break;
      case 'hang_up':
        this.hangUp();
        this.enqueue(this.code, MSG.hang_up_remote());
        break;
      case 'hang_up_remote':
        this.hangUp();
        break;
      case 'toggle_record_audio':
        sVideoSession.fire({ recordAudio: payload });
        break;
      case 'toggle_record_video':
        sVideoSession.fire({ recordVideo: payload });
        break;
      case 'record_media_request':
        const { media: request_media, on } = payload;
        verifyMediaType(request_media);

        const field = 'record' + request_media;
        sVideoSession.fire({ [field]: on ? 'pending' : 'unset' });
        break;
      case 'record_media_response':
        const { media, confirmed } = payload;
        verifyMediaType(media);

        sVideoSession.fire({
          ['record' + media]: confirmed ? 'confirmed' : 'denied'
        });
        break;
    }
  }

  registerForStreamEvent() {
    throw new Error('Not Implemented');
  }
}
