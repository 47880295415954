import React, { useEffect } from 'react';
import styled from 'styled-components/native';
import { VideoCall } from '../components/VideoCall';
import { ErsoButton } from '../components/buttons/Button';
import { eInternalEnqueue, sSession, sVideoSession } from '../lib/session';
import { initLocalStream } from '../lib/video';
import { H2 } from '../style/theme';
import { useEhEvent, useEhState } from '@horos/eh-react-hooks';
import { Margin } from '../components/Layout';

const Container = styled.View`
  width: 100%;
  /* height: fit-content; */
  /* height: 100%; */
  align-items: center;
  /* padding-top: 20px; */
  justify-content: space-evenly;

  /* background-color: red; */
`;

function ConfirmScreenVideoControl({ type }) {
  switch (type) {
    case 'video_off':
      if (sVideoSession.state.localVideoStream) {
        sVideoSession.state.localVideoStream.release();
        sVideoSession.fire({ localVideoStream: undefined });
      }
      break;
    case 'video_on':
      return initLocalStream();
  }
}

export function Confirm() {
  const { localAudioStream } = useEhState(sVideoSession);
  console.log('localStream', localAudioStream);

  useEffect(() => {
    initLocalStream();
  }, []);

  useEhEvent(eInternalEnqueue, ConfirmScreenVideoControl);

  return (
    <Container>
      <Margin height={20} />
      <H2>Confirm Connection</H2>
      <Margin height={20} />
      <ErsoButton
        text="Start"
        onPress={() => sSession.fire({ confirmed: true })}
      />
      <Margin height={20} />
      <VideoCall
        forceState="Press Start when you are ready"
        localStreamOnBigScreenUntilConnection
        user="Confirm"
        disableVideoControls
      />
      <Margin height={20} />
    </Container>
  );
}
