import { mediaDevices } from './webrtc';
import { sVideoSession } from './session';
import { Platform } from 'react-native';

const videoRequest = Platform.select({
  web: () => ({}),
  native: (videoSourceId) => ({
    width: 400,
    height: 400,
    frameRate: 30,
    facingMode: 'user',
    deviceId: videoSourceId
  })
});

export async function getLocalStream(video = true) {
  const sourceInfos = await mediaDevices.enumerateDevices();
  console.log('getLocalStream sourceInfos', { video, sourceInfos });

  let videoSourceId;
  for (let i = 0; i < sourceInfos.length; i++) {
    const sourceInfo = sourceInfos[i];
    if (
      sourceInfo.kind === 'videoinput' &&
      (Platform.OS === 'web' || sourceInfo.facing === 'front')
    ) {
      videoSourceId = sourceInfo.deviceId;
    }
  }
  const videoStream =
    video &&
    (await mediaDevices.getUserMedia({
      // audio: true,
      video: videoRequest(videoSourceId)
    }));

  const audioStream = await mediaDevices.getUserMedia({ audio: true });
  return [videoStream, audioStream];
}

export async function initLocalStream() {
  if (
    sVideoSession.state.localVideoStream &&
    sVideoSession.state.localAudioStream
  ) {
    console.log('sVideoSession', {
      localVideoStream: sVideoSession.state.localVideoStream,
      localAudioStream: sVideoSession.state.localAudioStream
    });
    return [
      sVideoSession.state.localVideoStream,
      sVideoSession.state.localAudioStream
    ];
  } else {
    const streams = await getLocalStream(sVideoSession.state.videoOn);
    const [localVideoStream, localAudioStream] = streams;
    sVideoSession.fire({ localVideoStream, localAudioStream });
    return streams;
  }
}
