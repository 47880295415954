import { useEhState } from '@horos/eh-react-hooks';
import React from 'react';
import { Frame } from './components/Frame';
import { sSession } from './lib/session';
import { Confirm } from './screens/Confirm';
import { Connect } from './screens/Connect';
import { Operator } from './screens/Operator';
import { Patient } from './screens/Patient';
import { SessionCreator } from './screens/SessionCreator';
import { StageModal } from './components/StageModal';
import { SeeYou } from './screens/SeeYou';
import { ToastProvider } from 'react-native-toast-notifications';
import { Platform } from 'react-native';
import { H1 } from './style/theme';
import { MediaRecordingModal } from './components/MediaRecordingModal';

const hasMediaRecorder = Boolean(Platform.OS !== 'web' || window.MediaRecorder);

export default function App() {
  const { connected, confirmed, type, patient_id, terminated } =
    useEhState(sSession);

  let screen;
  if (!connected) {
    screen = <Connect />;
  } else if (type === 'operator') {
    if (!patient_id || patient_id === 0) {
      screen = <SessionCreator />;
    } else if (!confirmed) {
      screen = <Confirm />;
    } else screen = <Operator />;
  } else if (type === 'patient') {
    if (terminated) screen = <SeeYou />;
    else if (!confirmed) {
      screen = <Confirm />;
    } else screen = <Patient />;
  }

  if (false === hasMediaRecorder) {
    return (
      <Frame>
        <H1>Sorry, your device is not compatible.</H1>
      </Frame>
    );
  }

  return (
    <ToastProvider>
      <Frame>
        {screen}
        <StageModal />
        {type === 'patient' && <MediaRecordingModal />}
      </Frame>
    </ToastProvider>
  );
}
