import { useEhState, withEhStates } from '@horos/eh-react-hooks';
import React from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-native';
import styled from 'styled-components/native';
import { InternalEnqueue, MSG, sVideoSession } from '../lib/session';
import { sTheme } from '../lib/theme';
import { getTogglerOnColor } from '../lib/utils';
import { Button } from './buttons/Button';
import { ModalContainer } from './Modal';
import ToggleSwitch from './ToggleSwitch';

const DarkModalContainer = styled(ModalContainer)`
  background-color: rgba(0, 0, 0, 0.9);
`;
const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 75%;
  max-width: 500px;
  height: 40px;
  margin-bottom: 20px;
`;

const Question = styled.Text`
  font-size: 18px;
  color: white;
`;

const Title = withEhStates(
  styled.Text`
    font-size: 25px;
    color: ${({ theme }) => theme.colors.main};
  `,
  sTheme
);

const Dummy = styled.View`
  height: 100%;
  width: 1px;
  background: transparent;
`;

export function MediaRecordingModal() {
  const { recordAudio, recordVideo, mediaRecordModal } =
    useEhState(sVideoSession);
  const { theme } = useEhState(sTheme);

  useEffect(() => {
    if (recordAudio === 'pending' || recordVideo === 'pending')
      sVideoSession.fire({ mediaRecordModal: true });
  }, [recordAudio, recordVideo]);

  return (
    <Modal visible={mediaRecordModal} animationType="slide" transparent={true}>
      <DarkModalContainer
        contentContainerStyle={{
          alignItems: 'center',
          minHeight: '100%',
          height: '100%',
          justifyContent: 'center'
        }}>
        <Row>
          <Dummy />
          <Title>Confirm Media Recording</Title>
          <Dummy />
        </Row>
        <Row>
          <Question>Confirm Audio Recording</Question>
          <ToggleSwitch
            isOn={recordAudio === 'confirmed'}
            onColor={getTogglerOnColor(recordAudio, theme)}
            offColor={theme.colors.secondary}
            label="Record Audio"
            labelStyle={{ color: 'white', fontWeight: '900' }}
            size="medium"
            onToggle={(isOn) => {
              console.log('RecordAudio isOn', isOn);
              InternalEnqueue(MSG.record_media_response('Audio', isOn));
              // InternalEnqueue(MSG.toggle_record_audio(isOn));
            }}
          />
        </Row>
        <Row>
          <Question>Confirm Video Recording</Question>
          <ToggleSwitch
            isOn={recordVideo === 'confirmed'}
            onColor={getTogglerOnColor(recordVideo, theme)}
            offColor={theme.colors.secondary}
            label="Record Video"
            labelStyle={{ color: 'white', fontWeight: '900' }}
            size="medium"
            onToggle={(isOn) => {
              console.log('RecordVideo isOn', isOn);
              InternalEnqueue(MSG.record_media_response('Video', isOn));
              // InternalEnqueue(MSG.toggle_record_audio(isOn));
            }}
          />
        </Row>
        <Row>
          <Dummy />
          <Button
            mode="warning"
            text="Save"
            onPress={() => {
              sVideoSession.fire({ mediaRecordModal: false });
              if (recordVideo === 'pending')
                InternalEnqueue(MSG.record_media_response('Video', false));
              if (recordAudio === 'pending')
                InternalEnqueue(MSG.record_media_response('Audio', false));
            }}
          />
          <Dummy />
        </Row>
      </DarkModalContainer>
    </Modal>
  );
}
