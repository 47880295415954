import { resi } from '../resi';
import { dequeuer, MSG, sSession, sVideoSession } from '../session';
import { initLocalStream } from '../video';
import { Ramirez } from './ramirez';
// import InCallManager from 'react-native-incall-manager';

export class OperatorRamirez extends Ramirez {
  constructor() {
    super('Operator', resi.operator.enqueue);
  }
  async start(sessionCode, isRestart = false) {
    super.start(sessionCode);
    sSession.fire({ code: sessionCode });

    if (false === isRestart)
      dequeuer(sessionCode, 'operator', async (msg, cycle) => {
        const { type, payload } = msg;
        console.log('OPERATOR DEQUEUE', { type });
        switch (type) {
          case 'answer':
            await this.pc.setRemoteDescription(payload);
            console.log('Operator after Set Remote');
            sSession.fire({ patient_answer: payload });
            break;
          case 'ice_candidate':
            if (!payload || payload === '') {
              console.warn('OPERATOR Ignoring bad ICE candidate');
            }
            return this.pc.addIceCandidate(payload).catch((e) =>
              console.warn('OPERATOR failed to add ice candidate', {
                e,
                payload
              })
            );
          case 'toggle_recording':
            sVideoSession.fire({ isRecording: payload });
            break;
          case 'toggle_recording_fake':
            sVideoSession.fire({ isRecordingFake: payload });
            break;
          case 'allow_recording':
            sVideoSession.fire({ recordingAllowed: true });
            break;

          case 'toggle_record_audio':
          case 'toggle_record_video':
            this.enqueue(this.code, msg);
            return this.commonHandler(msg);
          case 'record_media_request':
            this.enqueue(this.code, msg);
            return this.commonHandler(msg);
          default:
            return this.commonHandler(msg);
        }
      });

    this.init();
  }

  /**
   *
   * @param {import('react-native-webrtc').RTCPeerConnection} pc
   * @param {import('react-native-webrtc').MediaStream} localStream
   */
  async initializeCall() {
    this.pc.onicecandidate = (e) => {
      resi.operator.enqueue(this.code, MSG.ice_candidate(e.candidate));
    };

    this.registerForStreamEvent();

    await this.initStream();
    console.log('Operator sending offer');
    const offer = await this.pc.createOffer();
    await this.pc.setLocalDescription(offer);
    await resi.operator.enqueue(this.code, MSG.offer(offer));
    sSession.fire({ operator_offer: offer });
  }

  async init() {
    console.log('Operator Ramirez Init');
    await initLocalStream();
    this.createPeerConnection();
    await this.initializeCall(() => {
      console.log('Operator Ramirez Call Failed, creating new offer');
      this.init();
    });
  }

  toggleRecording() {
    resi.operator.enqueue(
      this.code,
      MSG.toggle_recording(!sVideoSession.state.isRecording)
    );
  }
}
