import { useEhState } from '@horos/eh-react-hooks';
import React from 'react';
import styled from 'styled-components/native';
import { sTheme } from '../lib/theme';

const Container = styled.View`
  flex-direction: row;
  align-items: center;
`;

const StatusCircle = styled.View`
  width: 10px;
  height: 10px;
  border-radius: 5px;
`;

const StatusText = styled.Text`
  margin-horizontal: 10px;
`;

const STATUS_COLOR_MAP = {
  idle: () => 'blue',
  connecting: () => 'orange',
  live: () => 'green',
  completed: () => 'green',
  connected: () => 'green',
  recording: (theme) => theme.colors.secondary,
  failed: () => 'grey',
  closed: () => 'black',
  disconnected: () => 'red',
  new: () => 'pink'
};

/**
 *
 * @param {{status: 'idle' | 'connecting' | 'live' | 'recording'}} param0
 */
export function CallStatus({ style, status = 'idle' }) {
  const { theme } = useEhState(sTheme);
  return (
    <Container style={style}>
      <StatusCircle
        style={{
          backgroundColor: STATUS_COLOR_MAP[status]
            ? STATUS_COLOR_MAP[status](theme)
            : 'blue'
        }}
      />
      <StatusText>{status}</StatusText>
    </Container>
  );
}
